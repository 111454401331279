/* eslint-disable no-await-in-loop */
import { useQuery } from '@apollo/client';
import Redirect from '@components/Redirect';
import { IRoute } from '@components/Routes';
import { clearAuth, setStorageItem } from '@components/storage/storage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  isFeatureAvailable,
  isOptionAvailable,
  useDomainFeatures,
  useDomainOptions,
} from '@/common/hooks/domainFeatures.hooks';
import { GlobalSnackbarNotification } from '@components/GlobalSnackbarNotification';
import client, { pleaseSignIn, refreshAuthenticationState } from '@components/client/client';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import PreloaderPanel from '@shared/components/PreloaderPanel';
import './assets/fonts/Onest.css';
import { observable } from '@legendapp/state';
import { useSelector } from '@legendapp/state/react';
import * as Sentry from '@sentry/react';
import { wait } from '@shared/utils/promises';
import { IFeatures } from 'typings';
import PageError from '@shared/components/PageError';
import Button from '@shared/components/Button';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getNetworkError } from '@shared/utils/apollo';
import { OptionType, Role } from './client/generated/graphql';
import { AVAILABLE_FEATURES_QUERY, GET_SELECTED_TARIFF_QUERY, USER_QUERY } from './client/queries';
import {
  administratorPrivateRoutesProps,
  getOwnerPrivateRoutes,
  operatorPrivateRoutesProps,
  publicRoutesProps,
  getSystemPrivateRoutes,
  userPrivateRoutesProps,
} from './routes';
import { getCurrentDomain, getRole } from './utils';

type CrucialDataLoadStateStatus = 'none' | 'loading' | 'success' | 'error';
type CrucialDataLoadState = {
  status: CrucialDataLoadStateStatus;
};
export const crucialDataLoadState$ = observable<CrucialDataLoadState>({ status: 'none' });

const fetchHandlerBasicRetryDelayMS = 500;
const fetchHandlerRetryNetworkErrorDelayMS = 2500;

const loadCrucialData = async () => {
  if (crucialDataLoadState$.status.get() === 'loading') {
    return;
  }

  crucialDataLoadState$.status.set('loading');

  let queryUserRetryCount = 3;
  let queryTariffOptionsRetryCount = 3;
  let queryFeaturesRetryCount = 3;

  const tryToFetchUserCoupleOfTimes = async () => {
    while (true) {
      const result = await client
        .query({ query: USER_QUERY })
        .then(() => 'success' as const)
        .catch((error) => {
          Sentry.captureMessage('Cannot get initial user data. Exception will be sent to Sentry.');
          Sentry.captureException(error);
          return error;
        });
      if (result === 'success') {
        return 'success' as const;
      }

      if (getNetworkError(result)) {
        await wait(fetchHandlerRetryNetworkErrorDelayMS);
        continue;
      }
      // if it was graphql error, decrement counter

      queryUserRetryCount -= 1;
      if (queryUserRetryCount <= 0) {
        return 'error' as const;
      }

      await wait(fetchHandlerBasicRetryDelayMS);
    }
  };

  const tryToFetchTariffOptionsCoupleOfTimes = async () => {
    while (true) {
      const result = await client
        .query({ query: GET_SELECTED_TARIFF_QUERY })
        .then(() => 'success' as const)
        .catch((error) => {
          Sentry.captureMessage(
            'Cannot get tariff options data. Exception will be sent to Sentry.'
          );
          Sentry.captureException(error);
          return error;
        });
      if (result === 'success') {
        return 'success' as const;
      }

      if (getNetworkError(result)) {
        await wait(fetchHandlerRetryNetworkErrorDelayMS);
        continue;
      }

      queryTariffOptionsRetryCount -= 1;
      if (queryTariffOptionsRetryCount <= 0) {
        return 'error' as const;
      }

      await wait(fetchHandlerBasicRetryDelayMS);
    }
  };

  const tryToFetchFeaturesCoupleOfTimes = async () => {
    while (true) {
      const result = await client
        .query({ query: AVAILABLE_FEATURES_QUERY })
        .then(() => 'success' as const)
        .catch((error) => {
          Sentry.captureMessage('Cannot get features data. Exception will be sent to Sentry.');
          Sentry.captureException(error);
          return error;
        });
      if (result === 'success') {
        return 'success' as const;
      }

      if (getNetworkError(result)) {
        await wait(fetchHandlerRetryNetworkErrorDelayMS);
        continue;
      }

      queryFeaturesRetryCount -= 1;
      if (queryFeaturesRetryCount <= 0) {
        return 'error' as const;
      }

      await wait(fetchHandlerBasicRetryDelayMS);
    }
  };

  const results = await Promise.all([
    tryToFetchUserCoupleOfTimes(),
    tryToFetchTariffOptionsCoupleOfTimes(),
    tryToFetchFeaturesCoupleOfTimes(),
  ]);

  if (results.every((result) => result === 'success')) {
    crucialDataLoadState$.status.set('success');
    return;
  }

  crucialDataLoadState$.status.set('error');
};

const useAppStyles = makeStyles(
  {
    errorWrapper: {
      width: '100vw',
      height: '100vh',

      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    column: {
      flexDirection: 'column',
    },
    root: {
      display: 'flex',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullVH: {
      height: '100vh',
    },
    buttonReturnToSignIn: {
      margin: '0 auto',
    },
    buttonReturnToSignInWrapper: {
      marginTop: '8px',
    },
  },
  {
    name: 'app',
    index: 1,
  }
);

const renderChildrenRedirect = (children: IRoute[]) => {
  if (children.length) {
    return <Route path={'*'} element={<Redirect to={children[0].path as string} />} />;
  }
  return null;
};

const renderRoute = (
  { path, children = [], disableRoute, option, feature, ...rest }: IRoute,
  features?: IFeatures,
  domainOptions?: (OptionType | '*')[]
) => {
  if (
    disableRoute &&
    ((feature && features !== undefined && !isFeatureAvailable(feature, features)) ||
      (option && domainOptions !== undefined && !isOptionAvailable(option, domainOptions)))
  ) {
    return null;
  }
  return (
    <Route key={path} path={`${path}/*`} {...rest}>
      {children.map((route) => renderRoute(route, features, domainOptions))}
      {renderChildrenRedirect(children)}
    </Route>
  );
};

const RoutesPublic = () => {
  return (
    <Routes>
      {publicRoutesProps.map((route) => renderRoute(route))}
      <Route path={'*'} element={<Redirect to={'/signin'} />} />
    </Routes>
  );
};

const RoutesAuthenticated = () => {
  const { features } = useDomainFeatures();
  const { options: domainOptions } = useDomainOptions();
  const navigate = useNavigate();

  const { data: dataAuthStateQuery } = useQuery<{ isAuthenticated?: boolean; role?: string }>(
    AUTHENTICATION_STATE_QUERY,
    {
      fetchPolicy: 'cache-only',
    }
  );
  const { data: dataUser } = useQuery(USER_QUERY);

  const currentDomain = getCurrentDomain(dataUser?.user);
  const clientType = currentDomain?.client?.type;
  const isAuthenticated = dataAuthStateQuery?.isAuthenticated;
  const role = getRole(dataAuthStateQuery?.role);
  const isPasswordRequired = dataUser?.user.isPasswordRequired;

  useEffect(() => {
    if (role === Role.Owner && isPasswordRequired) {
      navigate('/dashboard/welcome');
    }
  }, [isPasswordRequired, navigate, role]);

  const privateRoutes = useMemo(() => {
    switch (role) {
      case Role.Manager:
      case Role.Supporter:
      case Role.Sysadmin: {
        return getSystemPrivateRoutes({ clientType });
      }
      case Role.Owner: {
        return getOwnerPrivateRoutes({ clientType });
      }
      case Role.Admin: {
        return administratorPrivateRoutesProps;
      }
      case Role.User: {
        return userPrivateRoutesProps;
      }
      case Role.Operator: {
        return operatorPrivateRoutesProps;
      }
      default:
        return [];
    }
  }, [role, clientType]);

  const renderRouteAuthenticated = useCallback(renderRoute, [domainOptions, features]);

  const routes = useMemo(() => {
    if (privateRoutes.length <= 0) {
      pleaseSignIn();
      return null;
    }

    privateRoutes.sort((a, b) => (a.sort || 10) - (b.sort || 10));

    let routePath;
    switch (role) {
      case Role.User:
      case Role.Admin:
      case Role.Operator:
        routePath = '/history/';
        break;
      default:
        routePath = '/dashboard/';
    }

    return (
      <Routes>
        {privateRoutes.map((route) => renderRouteAuthenticated(route, features, domainOptions))}
        <Route path={'*'} element={<Redirect to={routePath} />} />
      </Routes>
    );
  }, [domainOptions, features, privateRoutes, renderRouteAuthenticated, role]);

  if (!isAuthenticated) {
    // Theoretically, we should not be there because
    // of parent's check
    return null;
  }

  return routes;
};

const RoutesSwitcherError = () => {
  const classes = useAppStyles();
  const [translate] = useTranslation();

  const handleClick = () => {
    crucialDataLoadState$.status.set('none');
    pleaseSignIn();
  };

  return (
    <div className={classes.errorWrapper}>
      <PageError />
      <div className={clsx(classes.buttonReturnToSignInWrapper, classes.center)}>
        <Button className={classes.buttonReturnToSignIn} onClick={handleClick}>
          {translate('RETURN_TO_SIGN_IN_PAGE')}
        </Button>
      </div>
    </div>
  );
};

const RoutesSwitcherDelayedError = () => {
  const [show, setShow] = useState(false);
  // We do not show error screen instantly to prevent screen blink
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setShow(true);
      timeout.current = null;
    }, 2000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, []);

  if (show) {
    return <RoutesSwitcherError />;
  }

  return null;
};

const RoutesSwitcher = () => {
  const classes = useAppStyles();
  const crucialDataLoadStatus = useSelector(crucialDataLoadState$.status);

  const {
    data: { isAuthenticated } = {
      isAuthenticated: false,
    },
  } = useQuery<{ isAuthenticated?: boolean; role?: string }>(AUTHENTICATION_STATE_QUERY, {
    fetchPolicy: 'cache-only',
  });

  // This is false by default to always initiate initial load
  const isAuthenticatedPrev = useRef(false);

  useEffect(() => {
    // If we switched from non-auth to auth, load crucial data
    if (!isAuthenticatedPrev.current && isAuthenticated) {
      loadCrucialData();
    }
    isAuthenticatedPrev.current = Boolean(isAuthenticated);
  }, [crucialDataLoadStatus, isAuthenticated]);

  const render = (children: React.ReactNode) => {
    return <div className={classes.root}>{children}</div>;
  };

  if (!isAuthenticated) {
    return render(<RoutesPublic />);
  }

  if (crucialDataLoadStatus === 'loading') {
    return <PreloaderPanel className={classes.fullVH} />;
  }

  if (crucialDataLoadStatus === 'error') {
    return <RoutesSwitcherError />;
  }

  if (crucialDataLoadStatus === 'success') {
    return render(<RoutesAuthenticated />);
  }

  return <RoutesSwitcherDelayedError />;
};

function TokenParamHandler() {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useAppStyles();

  const [loading, setLoading] = useState(true);
  const token = searchParams.get('token');
  const refreshToken = searchParams.get('refreshToken');
  const otpCode = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      clearAuth();
      setSearchParams(createSearchParams());
      setStorageItem('token', token);
      if (refreshToken) {
        setStorageItem('refreshToken', refreshToken);
      }
      refreshAuthenticationState()
        .then(() => {
          if (otpCode) {
            setTimeout(() => navigate(`/confirm-signup?code=${otpCode}`), 1000);
          }
        })
        .finally(() => setLoading(false));
      return;
    }
    setLoading(false);
    // We want to handle token query param receiving only once on application start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PreloaderPanel className={classes.fullVH} />;
  }

  return <RoutesSwitcher />;
}

function App() {
  return (
    <>
      <CssBaseline />
      <TokenParamHandler />
      <GlobalSnackbarNotification />
    </>
  );
}

export default App;
