import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { SlashIcon } from '@shared/assets/images/icons';
import { IHistoryList } from '../HistoryPage.interfaces';
import { useHistoryStyle } from '../HistoryPage.styles';

export const ClientCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();
  const [searchParams, setSearchParams] = useSearchParams();
  const [translate] = useTranslation();
  const sPhone = searchParams.get('phone');
  const sFilterName = searchParams.get('filterName');
  const sSearch = searchParams.get('search');

  function handleClientClick() {
    searchParams.set('search', '');
    searchParams.set('employee', '');
    searchParams.set('department', '');
    searchParams.set('other', '');
    searchParams.set('filterName', '');
    searchParams.set('via', '');
    searchParams.set('phone', item?.client || '');
    setSearchParams(searchParams);
  }

  if (item) {
    const { client } = item;
    const comment = item.blackListComment;
    return (
      <Flex direction="column">
        <Button
          aria-label="Filter by client name"
          className={classes.textHover}
          onClick={handleClientClick}
          variant="text"
        >
          <HighlightedText type={'phone'} text={client} query={sPhone || sFilterName || sSearch} />
        </Button>
        {item.isInBlackList && (
          <Flex className={classes.clientTag}>
            <SlashIcon />
            <Typography type="text4" color="tertiary500">
              {comment || translate('SPAM')}
            </Typography>
          </Flex>
        )}
      </Flex>
    );
  }

  return <div />;
};
