import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useDesktopPageStyle = makeStyles(
  ({
    color: { base, tertiary, success, links, danger, warning, primary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    desktopDataRoot: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '0 2em 1em',
    },
    body: {
      position: 'relative',
      height: '100%',
    },
    desktopDoughnutDiagram: {
      width: '9.5em',
      height: '9.5em',
      position: 'relative',
      marginBottom: '1em',
    },
    desktopDoughnutDiagramInfo: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      padding: '1em 1em 0.5em 1em',
      '& > *': {
        textAlign: 'center',
      },
      gap: '0.25em',
    },
    desktopPanelsWrapper: {
      width: '71em',
      flexWrap: 'wrap',
    },
    desktopSection: {
      padding: '1.5em',
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '20px',
      marginBottom: '1em',
      width: '35em',
      minHeight: '14.5em',
      boxSizing: 'border-box',
    },
    desktopSectionHeader: {
      paddingBottom: '1em',
    },
    desktopBalanceInfoItem: {
      textAlign: 'right',
      paddingLeft: '2.75em',
      '&:first-child': {
        paddingLeft: '0',
      },
    },
    desktopBalanceInfoItemFL: {
      textAlign: 'left',
      padding: '0.5em 0',
      borderBottom: `solid 1px ${tertiary[200]}`,
      '&:last-child': {
        paddingTop: '0',
      },
      '&:first-child': {
        border: 'none',
      },
    },
    desktopTelephonyRightSection: {
      width: '10.875em',
      minWidth: '10.875em',
      marginLeft: '1em',
    },
    desktopTelephonyInfo: {
      paddingBottom: '1em',
    },
    desktopTelephonyLine: {
      width: '10.875em',
    },
    desktopCallStatisticsDoughnut: {
      width: '8.875em',
      height: '8.875em',
      margin: '0 1em',
      position: 'relative',
    },
    desktopCallStatisticsContainer: {
      width: '100%',
      marginLeft: '4.5em',
      flexWrap: 'wrap',
    },
    desktopCallStatisticsInfoText: {
      marginTop: '1em',
      marginBottom: '1em',
      width: '50%',
      boxSizing: 'border-box',
      paddingRight: '2em',
      paddingLeft: '1em',
      position: 'relative',
      opacity: 1,
      transition: createTransition(['opacity'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      '&:after': {
        position: 'absolute',
        left: 0,
        top: '0.125em',
        bottom: '0.5em',
        width: '4px',
        borderRadius: '4px',
        content: '""',
      },
    },
    desktopCallStatisticsInfoTextFade: {
      opacity: 0.3,
    },
    desktopAfterOutgoingColor: {
      '&:after': {
        backgroundColor: success[600],
      },
    },
    desktopAfterIncomingColor: {
      '&:after': {
        backgroundColor: links[600],
      },
    },
    desktopAfterOutgoingMissedColor: {
      '&:after': {
        backgroundColor: warning[600],
      },
    },
    desktopAfterIncomingMissedColor: {
      '&:after': {
        backgroundColor: danger[600],
      },
    },
    desktopDoughnutDiagramBottomInfo: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 'calc(100% + 0.5em)',
    },
    desktopCallStatisticsCenterInfo: {
      width: '5em',
      height: '5em',
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    desktopPanelsCurrentPBS: {
      height: '4em',
      alignItems: 'center',
    },
    desktopPanelsCurrentPBSName: {
      paddingLeft: '0.5em',
    },
    desktopBarDiagramSection: {
      position: 'relative',
    },
    desktopTelephonyDiagram: {
      width: '19.625em',
    },
    tooltipWrapperClass: {
      background: base,
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '5px',
      color: tertiary[900],
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      transition: 'all .1s ease',
      '&:after, &:before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderTop: 'solid 0.75em transparent',
        borderBottom: 'solid 0.75em transparent',
      },
    },
    tooltipArrowRight: {
      '&:after': {
        borderRight: `solid 0.75em ${base}`,
        left: '-0.75em',
      },
      '&:before': {
        borderRight: `solid 0.75em ${tertiary[200]}`,
        marginLeft: '-1px',
        left: '-0.75em',
      },
    },
    tooltipLeftTextClass: {
      display: 'inline-block',
      fontSize: '0.75em',
      width: '8em',
      verticalAlign: 'middle',
    },
    tooltipArrowLeft: {
      '&:after': {
        borderLeft: `solid 0.75em ${base}`,
        right: '-0.75em',
      },
      '&:before': {
        borderLeft: `solid 0.75em ${tertiary[200]}`,
        marginRight: '-1px',
        right: '-0.75em',
      },
    },
    tooltipRightTextClass: {
      fontFamily: 'Onest-Medium, sans-serif',
      display: 'inline-block',
      textAlign: 'right',
      fontSize: '0.75em',
      width: '3em',
      verticalAlign: 'middle',
    },
    tooltipIconClass: {
      width: '1em',
      height: '1em',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '0.5em',
    },
    tooltipCurrentValue: {
      fontWeight: 'bold',
    },
    banner: {
      borderRadius: '1.5em',
      backgroundColor: `${primary[800]}`,
      height: '9.5em',
      padding: '1em 1.5em 0 2em',
      width: '71em',
      marginBottom: '1em',
    },
    bannerBlock: {
      borderRadius: '1.5em',
      backgroundColor: `${danger[600]}`,
      height: '6em',
      padding: '1em 1.5em 0 1.5em',
      width: '71em',
      marginBottom: '1em',
    },
    bannerAlert: {
      borderRadius: '1.5em',
      backgroundColor: `${warning[600]}`,
      height: '7em',
      padding: '1em 1.5em 0 1.5em',
      width: '71em',
      marginBottom: '1em',
    },
    bannerText: {
      color: 'white',
      marginTop: '0.25em',
    },
    bannerText2: {
      color: 'white',
    },
    bannerRemark: {
      color: 'white',
    },
    bannerLeft: {
      marginBottom: '1em',
      maxWidth: '75%',
      width: '37em',
    },
    bannerIcon: {
      marginBottom: '1em',
      width: '3em',
    },
    bannerRight: {
      marginLeft: '15em',
      marginTop: '1em',
    },
    bannerRub: {
      color: `${primary[700]}`,
      fontSize: '12em',
      lineHeight: '0.75em',
      fontWeight: 'bold',
    },
    alert: {
      color: `${warning[700]}`,
    },
    alertIcon: {
      color: 'white',
      width: '1.5em',
      height: '1.5em',
      marginTop: '0.25em',
    },
    blockIcon: {
      color: 'white',
      width: '3em',
      height: '3em',
      margin: '-0.5em 0 0 -0.5em',
    },
    actionButton: {
      width: '11em',
      height: '2em',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
