import {
  BALANCE_DETAILS_QUERY,
  BALANCE_QUERY,
  GET_SELECTED_TARIFF_QUERY,
  GET_TELEPHONY_TOTAL_COST_QUERY,
  USER_QUERY,
} from '@/client/queries';
import BalanceDialog from '@/components/BalanceDialog/BalanceDialog';
import Breadcrumbs from '@/components/Breadcrumbs';
import PeriodSelect from '@/components/PeriodSelect/PeriodSelect';
import BodyContainer from '@/layouts/BodyContainer';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import {
  PaymentServiceModelWithId,
  supplyPaymentServiceModelsWithIds,
} from '@/utils/paymentServiceModel';
import { PeriodValues, useDateOptions } from '@/utils/useDateOptions';
import { useQuery } from '@apollo/client';
import LazyTable, { IColumn } from '@components/LazyTable';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import { DomainStatus } from '@components/typings/interfaces';
import { toPrecision } from '@components/utils';
import Button from '@shared/components/Button/Button';
import Flex from '@shared/components/Flex';
import PageError from '@shared/components/PageError';
import Preloader from '@shared/components/Preloader';
import Typography from '@shared/components/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientType } from '@/client/generated/graphql';
import { useBalanceStyle } from './Balance.styles';
import {
  BalanceAmountCell,
  BalanceAmountHeaderCell,
  BalanceDateCell,
  BalanceOperationCell,
  BalanceTypeCell,
} from './modules';
import { OperationType } from './Balance.interfaces';

export const columns: IColumn<PaymentServiceModelWithId>[] = [
  { id: 'name', label: 'OPERATION', Renderer: BalanceOperationCell },
  { id: 'type', width: '15em', minWidth: '15em', label: 'TYPE', Renderer: BalanceTypeCell },
  { id: 'date', width: '10em', minWidth: '10em', label: 'DATE', Renderer: BalanceDateCell },
  {
    id: 'amount',
    label: 'SUMMARY',
    align: 'right',
    Renderer: BalanceAmountCell,
    HeaderRenderer: BalanceAmountHeaderCell,
  },
];

export const Balance = () => {
  const classes = useBalanceStyle();
  const [translate] = useTranslation();
  const [isFillBalanceDialogOpen, setFillBalanceDialogOpen] = useState(false);
  const [isPromisePaymentDialogOpen, setPromisePaymentDialogOpen] = useState(false);

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const domain = userData && getCurrentDomain(userData.user);
  const clientType = domain?.client?.type;
  const isClientNotFL = clientType !== ClientType.Fl;

  const periodData = useDateOptions([
    PeriodValues.CurrentMonth,
    PeriodValues.PastMonth,
    PeriodValues.Custom,
  ]);
  const startMonth = periodData[PeriodValues.CurrentMonth].from;
  const endMonth = periodData[PeriodValues.CurrentMonth].to;

  const periodRangeDates = {
    from: startMonth,
    to: endMonth,
  };

  const queryData = useMemo(
    () => ({
      from: startMonth.toISOString(),
      to: endMonth.toISOString(),
      offset: null,
      limit: 1000,
    }),
    [startMonth, endMonth]
  );

  const {
    data: balanceDetailsData,
    refetch: refetchBalance,
    loading,
    error,
  } = useQuery(BALANCE_DETAILS_QUERY, {
    variables: queryData,
  });
  const {
    data: telephonyData,
    refetch: refetchTelephonyCost,
    loading: telephonyCostLoading,
    error: telephonyCostError,
  } = useQuery(GET_TELEPHONY_TOTAL_COST_QUERY, {
    variables: { data: { from: startMonth.toISOString(), to: endMonth.toISOString() } },
  });

  const { getReportPaymentsServices: balanceDetails = [] } = balanceDetailsData || {
    getReportPaymentsServices: [],
  };

  const balanceDetailsWithIds = supplyPaymentServiceModelsWithIds(balanceDetails);
  const { data: balanceData } = useQuery(BALANCE_QUERY);
  const { data: domainData } = useQuery(GET_SELECTED_TARIFF_QUERY, { fetchPolicy: 'cache-first' });
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWideSidebar = sidebarState?.isWide;
  const {
    getBalance: { balance = 0 },
  } = balanceData || { getBalance: {} };

  const canReplenishBalance = useMemo(
    () =>
      domain && domain?.status !== DomainStatus.Demo && domain?.status !== DomainStatus.Moderating,
    [domain]
  );

  const canPromisePayment = useMemo(
    () =>
      domain &&
      domain?.status !== DomainStatus.Demo &&
      domain?.status !== DomainStatus.Moderating &&
      domain?.status !== DomainStatus.Confirmed,
    [domain]
  );

  function handlePeriodChange(date: { from: Date; to: Date; period: string }) {
    refetchBalance({
      ...queryData,
      from: date.from.toISOString(),
      to: date.to.toISOString(),
    }).then();
    refetchTelephonyCost({ data: { from: date.from.toISOString(), to: date.to.toISOString() } });
  }

  function handlePromisePaymentSuccess() {
    refetchBalance(queryData).then();
  }

  function handleOpenBalanceDialog() {
    setFillBalanceDialogOpen(true);
  }

  function handleCloseBalanceDialog() {
    setFillBalanceDialogOpen(false);
  }

  function handleOpenPromiseDialog() {
    setPromisePaymentDialogOpen(true);
  }

  function handleClosePromiseDialog() {
    setPromisePaymentDialogOpen(false);
  }

  const renderBalance = () => {
    if (typeof balance !== 'number') {
      return (
        <Typography type={'text2'} color={'danger600'}>
          {translate('BALANCE_UNAVAILABLE')}
        </Typography>
      );
    }
    return (
      <Typography type={'text2'} color={balance < 0 ? 'danger600' : 'tertiary900'}>
        {toPrecision(balance)} ₽
      </Typography>
    );
  };

  const renderEmptyData = () => {
    if (loading)
      return (
        <Flex className={classes.marginTop2} justifyContent={'center'}>
          <Preloader />
        </Flex>
      );
    return (
      <Flex className={classes.rowWrapper} justifyContent={'center'}>
        <Typography type={'text3'}>{translate('NORESULT_BALANCE')}</Typography>
      </Flex>
    );
  };

  const renderBalanceDetails = () => (
    <LazyTable<PaymentServiceModelWithId>
      columns={columns}
      data={balanceDetailsWithIds}
      renderEmptyDataMessage={renderEmptyData()}
    />
  );

  const renderMessages = () => {
    if (canReplenishBalance) {
      return (
        <BalanceDialog
          isFillBalanceDialogOpen={isFillBalanceDialogOpen}
          isPromisePaymentDialogOpen={isPromisePaymentDialogOpen}
          onCloseBalanceDialog={handleCloseBalanceDialog}
          onClosePromiseDialog={handleClosePromiseDialog}
          onPromisePaymentSuccess={handlePromisePaymentSuccess}
        />
      );
    }
    return null;
  };

  const renderTotalBalance = () => {
    const telephonyCost = telephonyData?.getTelephonyTotalCost ?? 0;
    const balanceTotal = balanceDetailsWithIds.reduce(
      (acc, cur) => {
        switch (cur.type) {
          case OperationType.Periodic: {
            acc.periodic.cost += cur.amount;
            acc.total.cost -= cur.amount;
            break;
          }
          case OperationType.OneTime: {
            acc.onetime.cost += cur.amount;
            acc.total.cost -= cur.amount;
            break;
          }
          case OperationType.Telephony: {
            acc.telephony.cost += cur.amount;
            acc.total.cost -= cur.amount;
            break;
          }
          case OperationType.Credit: {
            break;
          }
          default: {
            acc.payments.cost += cur.amount;
            acc.total.cost += cur.amount;
          }
        }
        return acc;
      },
      {
        total: { cost: -telephonyCost, title: translate('TOTAL_PERIOD_COST') },
        payments: { cost: 0, title: translate('ACCOUNT_TOP_UP') },
        periodic: { cost: 0, title: translate('PERIODIC_SERVICES') },
        onetime: { cost: 0, title: translate('ONE_TIME_SERVICES') },
        telephony: { cost: telephonyCost, title: translate('CALL_COSTS') },
      }
    );
    const objKeys = Object.keys(balanceTotal) as Array<keyof typeof balanceTotal>;
    const hasSiblingDomains = Boolean(domainData?.getDomain.siblingDomains);

    return (
      <div className={classes.totalInfo}>
        <div className={classes.totalInfoHead} />
        {!loading && !telephonyCostLoading && (
          <div className={classes.totalInfoContent}>
            {hasSiblingDomains && (
              <div className={classes.financialInfo}>
                <Typography type="text4" color="tertiary900">
                  {translate('FINANCIAL_INFORMATION')}
                </Typography>
              </div>
            )}
            {objKeys.map((key) => {
              const { title, cost } = balanceTotal[key];
              const isNegative = key === 'total' && cost < 0;

              return (
                <div className={classes.totalInfoRow} key={key}>
                  <Typography type="text4" color="tertiary700">
                    {title}:
                  </Typography>
                  <Typography type="text3" color={isNegative ? 'danger600' : 'tertiary900'} bold>
                    {`${toPrecision(cost)} ₽`}
                  </Typography>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace} disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs>
          <div>
            <PeriodSelect<PeriodValues>
              periodName={'currentMonth'}
              periodList={periodData}
              datePeriod={periodRangeDates}
              onPeriodChange={handlePeriodChange}
              position={'right'}
            />
          </div>
        </Breadcrumbs>
        {error || telephonyCostError ? (
          <PageError />
        ) : (
          <>
            <Flex className={classes.tableWrap}>
              {renderTotalBalance()}
              {renderBalanceDetails()}
            </Flex>
            {!loading && (
              <div className={classes.balance}>
                <Typography
                  className={isWideSidebar && classes.currentBalanceMargin}
                  type={'text2'}
                  color={'tertiary900'}
                >
                  {`${translate('CURRENT_BALANCE')}:`}
                </Typography>
                <div className={classes.balanceNumber}>{renderBalance()}</div>
                <div className={classes.balanceActions}>
                  {canReplenishBalance && (
                    <>
                      {canPromisePayment && isClientNotFL && (
                        <div className={classes.balanceControls}>
                          <Button
                            variant={'secondary'}
                            title={translate('PROMISED_PAYMENT')}
                            onClick={handleOpenPromiseDialog}
                          />
                        </div>
                      )}
                      <div className={classes.balanceControls}>
                        <Button
                          title={translate(isClientNotFL ? 'GENERATE_INVOICE' : 'FILL_UP_BALANCE')}
                          onClick={handleOpenBalanceDialog}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {renderMessages()}
          </>
        )}
      </Flex>
    </BodyContainer>
  );
};
