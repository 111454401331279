import { ClientType, Gender, UpdateClientPassport } from '@/client/generated/graphql';
import { UPDATE_CLIENT_PASSPORT_MUTATION, UPLOAD_DOCUMENT_MUTATION } from '@/client/mutations';
import {
  CLIENT_QUERY,
  GET_SUGGEST_BANK_QUERY,
  GET_SUGGEST_COMPANY_QUERY,
  GET_SUGGEST_FMS_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { ContractLayout } from '@/features/Documents';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import HighlightedText from '@components/HighlightedText';
import NotificationMessage from '@components/NotificationMessage';
import PagePreloader from '@components/PagePreloader';
import UploadField, { uploadFieldOnChangeData } from '@components/UploadField';
import {
  DomainStatus,
  genderOptions,
  payerTypeOptionsList,
  suggestBankItemType,
  suggestCompanyItemType,
  suggestFMSItemType,
} from '@components/typings/interfaces';
import {
  ImagesMIMEType,
  SelfiesMIMETypes,
  setSuggestBankList,
  setSuggestCompanyList,
  setSuggestFMSList,
  setUploadFieldProgress,
} from '@components/utils';
import { FormControlLabel, debounce } from '@material-ui/core';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { HelpCircleIcon } from '@shared/assets/images/icons';
import Alert from '@shared/components/Alert';
import Button from '@shared/components/Button/Button';
import CheckboxField from '@shared/components/CheckboxField';
import ComboBoxField, { ComboBoxFieldReasonType } from '@shared/components/ComboBoxField';
import DateField from '@shared/components/DateField';
import EmailField from '@shared/components/EmailField';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import MaskedField from '@shared/components/MaskedField';
import MessageDialog from '@shared/components/MessageDialog';
import PhoneField from '@shared/components/PhoneField';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { dateToDateOnlyString } from '@shared/utils/dates';
import clsx from 'clsx';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PreviewPhoto } from '@components/assets/images';
import {
  setDefaultFlValues,
  setDefaultIpValues,
  setDefaultUlValues,
} from '@/features/Documents/documents.utils';
import { ClientPassportFields } from '@/features/Documents/documents.types';
import * as Sentry from '@sentry/react';
import useThrottledCallback from '@shared/utils/hooks/useThrottledCallback';
import PageError from '@shared/components/PageError';
import { validateAndGetSelfieFileForUploading } from '@components/utils/documents';
import { formType } from '../../Contract.constants';
import { useContractStyle } from '../../Contract.styles';

const ContractPersonalData = () => {
  const classes = useContractStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [, setRerenderCounterState] = useState(0);
  const rerender = () => {
    setRerenderCounterState((v) => v + 1);
  };

  const rootRef = useRef<HTMLDivElement | null>(null);

  const formMethods = useForm<ClientPassportFields>({
    defaultValues: {
      dateOfBirth: null,
      dateOfIssue: null,
      personalDataAgreement: true,
      type: null,
    },
    shouldUnregister: true,
  });
  const { watch, setValue, setError, handleSubmit, clearErrors } = formMethods;
  const currentType = watch('type');
  const IsPostalAddressDuplicate = watch('IsPostalAddressDuplicate');
  const legalAddress = watch('legalAddress');
  const personalDataAgreement = watch('personalDataAgreement');

  const { data: clientData, loading: loadingClientData } = useQuery(CLIENT_QUERY);
  const { passport, type, params, domains, isModerated } = clientData?.getClient || {};
  const clientType = type;
  const hasDataBeenAlreadySubmitted = typeof isModerated === 'boolean';

  const { loading: loadingUserData } = useQuery(USER_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const {
    data: { suggestCompany = [] } = {},
    refetch: fetchSuggestCompany,
    loading: loadingSuggestCompany,
  } = useQuery(GET_SUGGEST_COMPANY_QUERY, {
    variables: { searchText: '', type: currentType === ClientType.Ul ? 'LEGAL' : 'INDIVIDUAL' },
    fetchPolicy: 'cache-first',
  });

  const {
    data: { suggestBank = [] } = {},
    refetch: fetchSuggestBank,
    loading: loadingSuggestBank,
  } = useQuery(GET_SUGGEST_BANK_QUERY, {
    variables: { searchText: '' },
    fetchPolicy: 'cache-first',
  });

  const {
    data: { suggestFMS = [] } = {},
    refetch: fetchSuggestFMS,
    loading: loadingSuggestFMS,
  } = useQuery(GET_SUGGEST_FMS_QUERY, {
    variables: { searchText: '' },
    fetchPolicy: 'cache-first',
  });

  const [uploadFile] = useMutation(UPLOAD_DOCUMENT_MUTATION);
  const [updateClientPassport, { loading: loadingUpdateClientPassport }] = useMutation(
    UPDATE_CLIENT_PASSPORT_MUTATION,
    { refetchQueries: [CLIENT_QUERY] }
  );

  const legalTypeDefaultValue = passport ? clientType : undefined;
  const companyList = setSuggestCompanyList(suggestCompany);
  const bankList = setSuggestBankList(suggestBank);
  const fmsList = setSuggestFMSList(suggestFMS);

  const sentryCaptureExceptionThrottled = useThrottledCallback((error: Error) => {
    Sentry.captureException(error);
  }, 60_000);

  const isSiblingDomainCommercial =
    !!domains &&
    domains.length > 1 &&
    domains.some(({ status }) => status === DomainStatus.Commercial);

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Finish)
      navigate(blockedPath || '/register/select-tariff');
  }, [isChanged, navigate, blockedPath]);

  const resetFieldsOnTypeChange = (typeOfClient: ClientType) => {
    Object.entries(formMethods.getValues()).forEach(([fieldName]) => {
      const fieldNameTyped = fieldName as keyof ClientPassportFields;

      if (fieldNameTyped === 'type' || fieldNameTyped === 'phone' || fieldNameTyped === 'email') {
        return;
      }

      if (
        fieldNameTyped === 'personalDataAgreement' ||
        fieldNameTyped === 'IsPostalAddressDuplicate'
      ) {
        formMethods.setValue(fieldNameTyped, true);
        return;
      }

      if (fieldNameTyped === 'companyName') {
        formMethods.setValue(fieldNameTyped, '');
        formMethods.unregister(fieldNameTyped);
        return;
      }

      formMethods.unregister(fieldNameTyped);
    });

    if (typeOfClient === ClientType.Fl || typeOfClient === ClientType.Ip) {
      formMethods.setValue('gender', Gender.Male);
    }
  };

  const setDefaultFormValues = useCallback(
    (typeOfClient: ClientType) => {
      if (!typeOfClient) {
        return;
      }

      const phone = params?.contacts?.phones?.[0];
      const email = params?.contacts?.emails?.[0];

      switch (typeOfClient) {
        case ClientType.Fl:
          setDefaultFlValues(formMethods, passport, translate, phone, email);
          return;

        case ClientType.Ip:
          setDefaultIpValues(formMethods, passport, translate, phone, email);
          return;

        case ClientType.Ul:
          setDefaultUlValues(formMethods, passport, translate, phone, email);
          return;

        default: {
          typeOfClient satisfies never;
        }
      }
    },
    [formMethods, params?.contacts?.emails, params?.contacts?.phones, passport, translate]
  );

  const areFieldsReadonly =
    loadingUpdateClientPassport || (isModerated !== null && isModerated !== undefined);

  // This useEffect sets default form values once when all
  // necessary request variables are received
  const haveDefaultValuesBeenSetOnce = useRef(false);
  useEffect(() => {
    if (!passport || !clientType || haveDefaultValuesBeenSetOnce.current) {
      return;
    }
    setDefaultFormValues(clientType);
    haveDefaultValuesBeenSetOnce.current = true;
  }, [clientType, passport, setDefaultFormValues]);

  const getUpdateFlPassportData = (fields: ClientPassportFields): UpdateClientPassport => {
    // TODO make sure date is correct and region insensitive
    const dateOfBirth = dateToDateOnlyString(fields.dateOfBirth);
    const dateOfIssue = dateToDateOnlyString(fields.dateOfIssue);

    return {
      type: ClientType.Fl,

      person: fields.person,
      genitiveCasePerson: fields.genitiveCasePerson,
      dateOfBirth,
      gender: fields.gender,

      series: fields.series,
      number: fields.number,
      departmentCode: fields.departmentCode,
      placeOfIssue: fields.placeOfIssue,
      dateOfIssue,

      inn: fields.inn,
      postalAddress: fields.postalAddress,
      legalAddress: fields.legalAddress,

      phone: fields.phone,
      email: fields.email,

      scans: {
        passport: fields.passportFrontFileHash,
        registration: fields.passportAddressFileHash,
        inn: fields.innFileHash,
        knd: fields.kndFileHash,
        selfie: fields.selfieFileHash,
      },
    };
  };

  const getUpdateIpPassportData = (fields: ClientPassportFields): UpdateClientPassport => {
    const dateOfBirth = dateToDateOnlyString(fields.dateOfBirth);
    const dateOfIssue = dateToDateOnlyString(fields.dateOfIssue);

    return {
      type: ClientType.Ip,

      companyName: fields.companyName,
      person: fields.person,
      genitiveCasePerson: fields.genitiveCasePerson,
      dateOfBirth,
      gender: fields.gender,

      series: fields.series,
      number: fields.number,
      departmentCode: fields.departmentCode,
      placeOfIssue: fields.placeOfIssue,
      dateOfIssue,

      inn: fields.inn,
      ogrn: fields.ogrn,
      edo: fields.edo || undefined,
      postalAddress: fields.postalAddress,
      legalAddress: fields.legalAddress,

      bankName: fields.bankName,
      bik: fields.bik,
      account: fields.account,
      correspondentAccount: fields.correspondentAccount,
      bankAddress: fields.bankAddress,

      phone: fields.phone,
      email: fields.email,

      scans: {
        passport: fields.passportFrontFileHash,
        registration: fields.passportAddressFileHash,
        inn: fields.innFileHash,
        egrip: fields.egripFileHash,
        card: fields.cardFileHash,
        selfie: fields.selfieFileHash,
      },
    };
  };

  const getUpdateUlPassportData = (fields: ClientPassportFields): UpdateClientPassport => {
    return {
      type: ClientType.Ul,

      companyName: fields.companyName,

      inn: fields.inn,
      ogrn: fields.ogrn,
      edo: fields.edo || undefined,
      kpp: fields.kpp,

      postalAddress: fields.postalAddress,
      legalAddress: fields.legalAddress,

      bankName: fields.bankName,
      bik: fields.bik,
      account: fields.account,
      correspondentAccount: fields.correspondentAccount,
      bankAddress: fields.bankAddress,

      person: fields.person,
      genitiveCasePerson: fields.genitiveCasePerson,
      position: fields.position,
      genitiveCasePosition: fields.genitiveCasePosition,
      actBasis: fields.actBasis,

      phone: fields.phone,
      email: fields.email,

      scans: {
        egrul: fields.egrulFileHash,
        inn: fields.innFileHash,
        authority: fields.authorityFileHash,
        card: fields.cardFileHash,
      },
    };
  };

  useEffect(() => {
    if (IsPostalAddressDuplicate) {
      setValue('postalAddress', legalAddress);
      clearErrors('postalAddress');
    }
  }, [IsPostalAddressDuplicate, legalAddress, setValue, clearErrors]);

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  const handleTypeChange = (typeOfClient: ClientType) => {
    clearErrors();
    resetFieldsOnTypeChange(typeOfClient);
    setDefaultFormValues(typeOfClient);

    setTimeout(rerender);

    // Workaround for companyName field reset (problem is possibly caused because field is rendered on the same location as for another client type)
    if (typeOfClient === ClientType.Ip || typeOfClient === ClientType.Ul) {
      setTimeout(() => {
        if (clientType === typeOfClient && passport?.companyName) {
          setValue('companyName', passport.companyName);
        } else {
          setValue('companyName', '');
        }
      }, 50);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestCompany = useCallback(
    debounce((value: string, legalTypeStr: string) => {
      fetchSuggestCompany({
        searchText: value,
        type: legalTypeStr === ClientType.Ul ? 'LEGAL' : 'INDIVIDUAL',
      });
    }, 500),
    [fetchSuggestCompany]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestBank = useCallback(
    debounce((value: string) => {
      fetchSuggestBank({ searchText: value });
    }, 500),
    [fetchSuggestBank]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestFms = useCallback(
    debounce((value: string) => {
      fetchSuggestFMS({ searchText: value });
    }, 500),
    [fetchSuggestFMS]
  );

  function handleSuggestCompanyChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('inn', '');
      setValue('ogrn', '');
      setValue('legalAddress', '');
      setValue('person', '');
      setValue('postalAddress', '');
      if (currentType === ClientType.Ul) {
        setValue('kpp', '');
        setValue('position', '');
        setValue('genitiveCasePosition', '');
      }
    }
    if (event && currentType) {
      debounceSuggestCompany(value, currentType);
    }
  }

  function handleSuggestBankChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('bik', '');
      setValue('correspondentAccount', '');
      setValue('bankAddress', '');
    }
    if (event) {
      debounceSuggestBank(value);
    }
  }

  function handleSuggestFMSChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('placeOfIssue', '');
    }
    if (event) {
      debounceSuggestFms(value);
    }
    handleFormChange();
  }

  function handleSuggestCompanySelect(
    event: ChangeEvent<unknown>,
    item: suggestCompanyItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('inn', item.data.inn);
      setValue('ogrn', item.data.ogrn);
      setValue('legalAddress', item.data.address);
      setValue('person', item.data.person || '');
      if (currentType === ClientType.Ul) {
        setValue('position', item.data?.position?.toLowerCase() || '');
        setValue('kpp', item.data.kpp);
        clearErrors('kpp');
      }
      if (IsPostalAddressDuplicate) {
        setValue('postalAddress', item.data.address);
        clearErrors('postalAddress');
      }
      clearErrors(['inn', 'ogrn', 'legalAddress', 'person', 'position']);
    }
    handleFormChange();
  }

  function handleSuggestBankSelect(
    event: ChangeEvent<unknown>,
    item: suggestBankItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('bik', item.data.bik);
      setValue('correspondentAccount', item.data.correspondentAccount);
      setValue('bankAddress', item.data.address);
      clearErrors(['bik', 'correspondentAccount', 'bankAddress']);
    }
    handleFormChange();
  }

  function handleSuggestFMSSelect(
    event: ChangeEvent<unknown>,
    item: suggestFMSItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('placeOfIssue', item.data.name);
      clearErrors('placeOfIssue');
    }
    handleFormChange();
  }

  const handleUpdateClientPassportError = (error: unknown) => {
    setIsConfirmDialogOpen(false);

    let titleText = 'SOMETHING_WENT_WRONG';
    let messageText = 'SOMETHING_WRONG_MESSAGE';
    const messageFromError = getErrorMessageFromGraphqlError(error);
    if (
      messageFromError === 'SERIES_NUMBER_IS_NOT_UNIQUE' ||
      messageFromError === 'INN_IS_NOT_UNIQUE'
    ) {
      titleText = 'NEW_ATS';
      messageText = 'NEW_ATS_MESSAGE';
    }
    setShowErrorDialog({
      title: titleText,
      message: messageText,
      showDialog: true,
    });
  };

  const handleUpdateClientPassportSuccess = () => {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
  };

  function handlePersonalDataSubmit(formData: ClientPassportFields) {
    if (!isConfirmDialogOpen) {
      setIsConfirmDialogOpen(true);
      return;
    }

    switch (currentType) {
      case ClientType.Fl: {
        updateClientPassport({
          variables: {
            data: getUpdateFlPassportData(formData),
          },
        })
          .then(handleUpdateClientPassportSuccess)
          .catch(handleUpdateClientPassportError);
        return;
      }

      case ClientType.Ip: {
        updateClientPassport({
          variables: {
            data: getUpdateIpPassportData(formData),
          },
        })
          .then(handleUpdateClientPassportSuccess)
          .catch(handleUpdateClientPassportError);
        return;
      }
      case ClientType.Ul: {
        updateClientPassport({
          variables: {
            data: getUpdateUlPassportData(formData),
          },
        })
          .then(handleUpdateClientPassportSuccess)
          .catch(handleUpdateClientPassportError);
        return;
      }
      case null:
      case undefined: {
        Sentry.captureException(
          Error(
            `${currentType} is not an expected value of client type in handlePersonalDataSubmit, ContractPersonalData`
          )
        );
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
        return;
      }
      default: {
        currentType satisfies never;
        Sentry.captureException(
          Error(
            `${currentType} is not an expected value of client type in handlePersonalDataSubmit, ContractPersonalData`
          )
        );
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      }
    }
  }

  function handleErrorDialogClose() {
    setShowErrorDialog({
      title: '',
      message: '',
      showDialog: false,
    });
  }

  async function handleDocumentUpload({ hashName, progressName, file }: uploadFieldOnChangeData) {
    if (!file) {
      return;
    }

    let comment = '';
    let documentType = '';

    const runFileUpload = () => {
      uploadFile({
        variables: {
          data: {
            type: documentType,
            comment,
          },
          file,
        },
        context: {
          fetchOptions: {
            onProgress: ({ loaded, total }: ProgressEvent) =>
              setUploadFieldProgress(loaded, total, setValue, progressName),
          },
        },
      })
        .then((res) => {
          setValue(hashName as keyof ClientPassportFields, res?.data?.uploadDocument?.filename);
        })
        .catch((error) => {
          const messageFromError = getErrorMessageFromGraphqlError(error);

          let message = translate('UPLOAD_ERROR');
          if (messageFromError === 'content type error') {
            message = translate('UPLOAD_DOCUMENT_ERROR');
          }

          setError(hashName as keyof ClientPassportFields, {
            type: 'error',
            message,
          });
        });

      handleFormChange();
    };

    switch (progressName) {
      case 'passportFrontFileProgressName':
        comment = translate('PASSPORT_DATA_PAGE');
        documentType = 'PASSPORT';
        break;
      case 'passportAddressFileProgressName':
        comment = translate('PASSPORT_ADDRESS_PAGE');
        documentType = 'PASSPORT';
        break;
      case 'innFileProgressName':
        comment = translate('INN_PAGE');
        documentType = 'SCAN';
        break;
      case 'kndFileProgressName':
        comment = translate('KND_PAGE');
        documentType = 'SCAN';
        break;
      case 'egrulFileProgressName':
        comment = translate('DOCUMENT_ON_STATE_REGISTRATION');
        documentType = 'SCAN';
        break;
      case 'egripFileProgressName':
        comment = translate('EGRIP');
        documentType = 'SCAN';
        break;
      case 'authorityFileProgressName':
        comment = translate('DOCUMENT_CONFIRMING_THE_AUTHORITY_OF_A_LEGAL_ENTITY');
        documentType = 'SCAN';
        break;
      case 'cardFileProgressName': {
        const getComment = () => {
          if (currentType === ClientType.Ip) {
            return translate('IP_DETAILS_CARD');
          }
          if (currentType === ClientType.Ul) {
            return translate('CARD_WITH_COMPANY_DETAILS');
          }
          return '';
        };
        comment = getComment();
        documentType = 'SCAN';
        break;
      }
      case 'selfieFileProgressName': {
        const result = await validateAndGetSelfieFileForUploading(file);
        if (result.type === 'error') {
          if (result.errorMessageCodeForHashField) {
            setError('selfieFileHash', { message: translate(result.errorMessageCodeForHashField) });
            return;
          }
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        // eslint-disable-next-line no-param-reassign
        file = result.file;

        comment = translate('PHOTO');
        documentType = 'SCAN';

        break;
      }
      default:
    }

    runFileUpload();
  }

  const renderCompanyOptions = (
    companyOption: suggestCompanyItemType,
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <div className={classes.customOptionRender}>
      <HighlightedText type={'text'} text={companyOption.data.name} query={inputValue} />
      <div className={clsx(classes.defaultElementWidth19, classes.textOverflow)}>
        <HighlightedText
          type={'text'}
          text={companyOption.data.inn}
          query={inputValue}
          typographyType={'text4'}
          typographyColor={'tertiary600'}
        />
        {companyOption.data.shortAddress && (
          <Typography type={'text4'} color={'tertiary600'}>
            {`, ${companyOption.data.shortAddress}`}
          </Typography>
        )}
      </div>
    </div>
  );

  const renderBankOptions = (
    bankOption: suggestBankItemType,
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <div className={classes.customOptionRender}>
      <HighlightedText type={'text'} text={bankOption.data.name} query={inputValue} />
      <div className={clsx(classes.defaultElementWidth19, classes.textOverflow)}>
        <HighlightedText
          type={'text'}
          text={bankOption.data.bik}
          query={inputValue}
          typographyType={'text4'}
          typographyColor={'tertiary600'}
        />
        <Typography type={'text4'} color={'tertiary600'}>
          {`, ${bankOption.data.address}`}
        </Typography>
      </div>
    </div>
  );

  const renderBankFields = () => {
    return (
      <>
        <div className={classes.personalDataFormItem}>
          <ComboBoxField
            name={'bankName'}
            loading={loadingSuggestBank}
            freeSolo
            valueKey={'value'}
            titleKey={'bankName'}
            onInputChange={handleSuggestBankChange}
            onChange={handleSuggestBankSelect}
            data={bankList}
            renderOption={renderBankOptions}
            placeholder={translate('CHOOSE_BANK')}
            label={translate('BANK_NAME')}
            readonly={areFieldsReadonly}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_BANK');
              }
              return true;
            }}
            filterOptions={(opts, state_) => {
              if (!state_.inputValue) return opts;
              const inputValueLowerCased = state_.inputValue.toLowerCase();
              return opts.filter(
                (opt) =>
                  opt.data.name.toLowerCase().includes(inputValueLowerCased) ||
                  opt.data.shortName.toLowerCase().includes(inputValueLowerCased) ||
                  opt.data.bik.toLowerCase().includes(inputValueLowerCased)
              );
            }}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'999999999'}
            name={'bik'}
            label={translate('BIK')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 9) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 9,
                  type: translate('BIK'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'99999999999999999999'}
            name={'account'}
            label={translate('CHECKING_ACCOUNT')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 20) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 20,
                  type: translate('CHECKING_ACCOUNT'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'99999999999999999999'}
            name={'correspondentAccount'}
            label={translate('CORRESPONDENT_ACCOUNT')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 20) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 20,
                  type: translate('CORRESPONDENT_ACCOUNT_SHORT'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'bankAddress'}
            multiline
            rows={3}
            label={translate('BANK_ADDRESS')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
      </>
    );
  };

  const renderFlFields = () => {
    return (
      <>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'person'}
            label={translate('FULL_NAME')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_FULLNAME');
              }
              return true;
            }}
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'genitiveCasePerson'}
            label={translate('GENITIVE_NAME')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_GENITIVE_NAME');
              }
              return true;
            }}
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>
        <div className={clsx(classes.personalDataFormItem, classes.defaultElementWidth12)}>
          <DateField
            name="dateOfBirth"
            label={translate('DATE_OF_BIRTH')}
            disableFuture
            required
            readonly={areFieldsReadonly}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <RadioGroup name={'gender'}>
            <div className={classes.personalDataRadioContainer}>
              <div className={classes.personalDataGenderContainer}>
                <Typography type={'text4'} color={'tertiary700'}>
                  {translate('GENDER')}
                </Typography>
                <div className={classes.personalDataGenderControls}>
                  {genderOptions.map(({ titleCode, value }) => (
                    <FormControlLabel
                      key={`gender-${titleCode}`}
                      value={value}
                      control={<Radio color={'secondary'} />}
                      label={translate(titleCode)}
                      onChange={handleFormChange}
                      disabled={areFieldsReadonly}
                    />
                  ))}
                </div>
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'999999999999'}
            name={'inn'}
            label={translate('INN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 12) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 12,
                  type: translate('INN'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItems}>
          <MaskedField
            mask={'9999'}
            name={'series'}
            label={translate('SERIES')}
            className={classes.defaultElementWidth6}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_SERIES');
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
          <MaskedField
            mask={'999999'}
            name={'number'}
            label={translate('PAS_NUMBER')}
            className={classes.passportNumber}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_NUMBER');
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <ComboBoxField
            label={translate('CODE')}
            name={'departmentCode'}
            loading={loadingSuggestFMS}
            freeSolo
            valueKey={'value'}
            titleKey={'departmentCode'}
            onChange={handleSuggestFMSSelect}
            readonly={areFieldsReadonly}
            onInputChange={(event, value, reason) => {
              if (reason === 'reset') return;
              handleSuggestFMSChange(event, value);
            }}
            data={fmsList}
            placeholder={translate('CHOOSE')}
            maxLength={10}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_CODE');
              }
              return true;
            }}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'placeOfIssue'}
            multiline
            rows={3}
            label={translate('WHO_GIVEN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_WHOGIVEN');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <DateField
            name={'dateOfIssue'}
            label={translate('GIVEN_DATE')}
            disableFuture
            required
            readonly={areFieldsReadonly}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'legalAddress'}
            multiline
            rows={3}
            label={translate('REGISTRATION_ADDRESS')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'postalAddress'}
            multiline
            rows={3}
            InputProps={{ readOnly: Boolean(IsPostalAddressDuplicate || areFieldsReadonly) }}
            label={translate('LIVING_ADDRESS')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'IsPostalAddressDuplicate'}
            label={translate('POSTAL_ADDRESS_MATCH')}
            onChange={handleFormChange}
            readOnly={areFieldsReadonly}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('MENU_CONTACTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFormItem}>
          <PhoneField
            name={'phone'}
            label={translate('PHONE')}
            required
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <EmailField
            name={'email'}
            label={translate('EMAIL')}
            required
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DOCUMENTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'passportFrontFileHash'}
              fileNameFieldName={'passportFrontFileName'}
              fileUrlFieldName={'passportFrontFileUrl'}
              fileProgressName={'passportFrontFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'passportAddressFileHash'}
              fileNameFieldName={'passportAddressFileName'}
              fileUrlFieldName={'passportAddressFileUrl'}
              fileProgressName={'passportAddressFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {translate('INN_UPLOAD')}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'innFileHash'}
              fileNameFieldName={'innFileName'}
              fileUrlFieldName={'innFileUrl'}
              fileProgressName={'innFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {translate('KND_UPLOAD')}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'kndFileHash'}
              fileNameFieldName={'kndFileName'}
              fileUrlFieldName={'kndFileUrl'}
              fileProgressName={'kndFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFormItem}>
          <div className={classes.photoPlaceholderWrapper}>
            <PreviewPhoto />
          </div>
          <UploadField
            className={clsx(classes.uploadButton, classes.marginBottom2)}
            label={translate('UPLOAD_PHOTO')}
            requiredText={translate('FILE_NOT_CHOSEN')}
            fileHashFieldName={'selfieFileHash'}
            fileNameFieldName={'selfieFileName'}
            fileUrlFieldName={'selfieFileUrl'}
            fileProgressName={'selfieFileProgressName'}
            onChange={handleDocumentUpload}
            accept={SelfiesMIMETypes}
            readonly={areFieldsReadonly}
            showViewButton
            required
          />
          <Alert
            classes={{ root: classes.photoUploadAlert }}
            status="info"
            description={
              <Flex direction="column">
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_1')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_2')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_3')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_4')}
                </Typography>
              </Flex>
            }
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DATA_PROCESSING')}
          </Typography>
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'personalDataAgreement'}
            label={translate('PERSONAL_DATA_AGREEMENT')}
            required
            readOnly={areFieldsReadonly}
            onChange={handleFormChange}
          />
          <NotificationMessage
            show={!personalDataAgreement}
            message={translate('PERSONAL_DATA_AGREEMENT_MESSAGE')}
          />
        </div>
      </>
    );
  };

  const renderIpFields = () => {
    return (
      <>
        <div className={classes.personalDataFormItem}>
          <ComboBoxField
            name={'companyName'}
            label={translate('COMPANY_NAME')}
            loading={loadingSuggestCompany}
            freeSolo
            valueKey={'value'}
            titleKey={'companyName'}
            onChange={handleSuggestCompanySelect}
            onInputChange={handleSuggestCompanyChange}
            data={companyList}
            placeholder={translate('EMPTY_COMPANY_OR_INN')}
            renderOption={renderCompanyOptions}
            readonly={areFieldsReadonly}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_COMPANY');
              }
              return true;
            }}
            filterOptions={(opts, state_) => {
              if (!state_.inputValue) return opts;
              const inputValueLowerCased = state_.inputValue.toLowerCase();
              return opts.filter(
                (opt) =>
                  opt.data.name.toLowerCase().includes(inputValueLowerCased) ||
                  opt.data.inn.toLowerCase().includes(inputValueLowerCased)
              );
            }}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'person'}
            label={translate('FULL_NAME')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_FULLNAME');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'genitiveCasePerson'}
            label={translate('GENITIVE_NAME')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_GENITIVE_NAME');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={clsx(classes.personalDataFormItem, classes.defaultElementWidth12)}>
          <DateField
            name="dateOfBirth"
            label={translate('DATE_OF_BIRTH')}
            disableFuture
            required
            readonly={areFieldsReadonly}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <RadioGroup name={'gender'}>
            <div className={classes.personalDataRadioContainer}>
              <div className={classes.personalDataGenderContainer}>
                <Typography type={'text4'} color={'tertiary700'}>
                  {translate('GENDER')}
                </Typography>
                <div className={classes.personalDataGenderControls}>
                  {genderOptions.map(({ titleCode, value }) => (
                    <FormControlLabel
                      key={`gender-${titleCode}`}
                      value={value}
                      control={<Radio color={'secondary'} />}
                      label={translate(titleCode)}
                      disabled={areFieldsReadonly}
                      onChange={handleFormChange}
                    />
                  ))}
                </div>
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className={classes.personalDataFormItems}>
          <MaskedField
            mask={'9999'}
            name={'series'}
            label={translate('SERIES')}
            className={classes.defaultElementWidth6}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_SERIES');
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
          <MaskedField
            mask={'999999'}
            name={'number'}
            label={translate('PAS_NUMBER')}
            className={classes.passportNumber}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_NUMBER');
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <ComboBoxField
            name={'departmentCode'}
            loading={loadingSuggestFMS}
            freeSolo
            valueKey={'value'}
            titleKey={'departmentCode'}
            onChange={handleSuggestFMSSelect}
            onInputChange={(event, value, reason) => {
              if (reason === 'reset') return;
              handleSuggestFMSChange(event, value);
            }}
            readonly={areFieldsReadonly}
            data={fmsList}
            placeholder={translate('CHOOSE')}
            label={translate('CODE')}
            maxLength={10}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_CODE');
              }
              return true;
            }}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'placeOfIssue'}
            multiline
            rows={3}
            label={translate('WHO_GIVEN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_WHOGIVEN');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <DateField
            name={'dateOfIssue'}
            label={translate('GIVEN_DATE')}
            disableFuture
            onChange={handleFormChange}
            readonly={areFieldsReadonly}
            required
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'999999999999'}
            name={'inn'}
            label={translate('INN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 12) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 12,
                  type: translate('INN'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'999999999999999'}
            name={'ogrn'}
            label={translate('OGRNIP')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 15) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 15,
                  type: translate('OGRNIP'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'edo'}
            label={translate('EDO_IDENTIFIER')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (value?.length > 255) {
                return translate('NOT_MORE_THAN_SYMBOLS', { amount: 255 });
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'legalAddress'}
            multiline
            rows={3}
            label={translate('LEGAL_ADDRESS')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'postalAddress'}
            multiline
            rows={3}
            InputProps={{ readOnly: Boolean(IsPostalAddressDuplicate || areFieldsReadonly) }}
            label={translate('POSTAL_ADDRESS')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'IsPostalAddressDuplicate'}
            label={translate('LEGAL_ADDRESS_MATCH')}
            onChange={handleFormChange}
            readOnly={areFieldsReadonly}
          />
        </div>

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('BANK_DETAILS')}
          </Typography>
        </div>

        {renderBankFields()}

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('MENU_CONTACTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFormItem}>
          <PhoneField
            name={'phone'}
            label={translate('PHONE')}
            onChange={handleFormChange}
            InputProps={{ readOnly: areFieldsReadonly }}
            required
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <EmailField
            name={'email'}
            label={translate('EMAIL')}
            onChange={handleFormChange}
            InputProps={{ readOnly: areFieldsReadonly }}
            required
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DOCUMENTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'passportFrontFileHash'}
              fileNameFieldName={'passportFrontFileName'}
              fileUrlFieldName={'passportFrontFileUrl'}
              fileProgressName={'passportFrontFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'passportAddressFileHash'}
              fileNameFieldName={'passportAddressFileName'}
              fileUrlFieldName={'passportAddressFileUrl'}
              fileProgressName={'passportAddressFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {translate('INN_UPLOAD')}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'innFileHash'}
              fileNameFieldName={'innFileName'}
              fileUrlFieldName={'innFileUrl'}
              fileProgressName={'innFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Flex alignItems="flexStart">
              <Typography className={classes.fileUploadLabel} type={'text4'} color={'tertiary600'}>
                {translate('EGRIP')}
              </Typography>
              <TooltipHover
                className={classes.hoverTooltipHelpCircleIconContainer}
                classes={{ tooltip: classes.hoverTooltip, arrow: classes.hoverTooltipArrow }}
                contentClass={classes.hoverTooltipContent}
                title={
                  <Typography pxToEmSize={12}>{translate('EGRIP_LONG_DESCRIPTION')}</Typography>
                }
                placement="right"
              >
                <HelpCircleIcon />
              </TooltipHover>
            </Flex>

            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'egripFileHash'}
              fileNameFieldName={'egripFileName'}
              fileUrlFieldName={'egripFileUrl'}
              fileProgressName={'egripFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>

        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {translate('IP_DETAILS_CARD')}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'cardFileHash'}
              fileNameFieldName={'cardFileName'}
              fileUrlFieldName={'cardFileUrl'}
              fileProgressName={'cardFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>

        <div className={clsx(classes.personalDataFormItem, classes.marginBottom1)}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('PHOTO')}
          </Typography>
        </div>
        <div className={classes.personalDataFormItem}>
          <div className={classes.photoPlaceholderWrapper}>
            <PreviewPhoto />
          </div>
          <UploadField
            className={clsx(classes.uploadButton, classes.marginBottom2)}
            label={translate('UPLOAD_PHOTO')}
            requiredText={translate('FILE_NOT_CHOSEN')}
            fileHashFieldName={'selfieFileHash'}
            fileNameFieldName={'selfieFileName'}
            fileUrlFieldName={'selfieFileUrl'}
            fileProgressName={'selfieFileProgressName'}
            onChange={handleDocumentUpload}
            accept={SelfiesMIMETypes}
            readonly={areFieldsReadonly}
            showViewButton
            required
          />
          <Alert
            classes={{ root: classes.photoUploadAlert }}
            status="info"
            description={
              <Flex direction="column">
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_1')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_2')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_3')}
                </Typography>
                <Typography className={classes.marginBottom05}>
                  {translate('UPLOAD_PHOTO_WITH_PASSPORT_ALERT_DESC_4')}
                </Typography>
              </Flex>
            }
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DATA_PROCESSING')}
          </Typography>
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'personalDataAgreement'}
            label={translate('PERSONAL_DATA_AGREEMENT')}
            onChange={handleFormChange}
            readOnly={areFieldsReadonly}
            required
          />
          <NotificationMessage
            show={!personalDataAgreement}
            message={translate('PERSONAL_DATA_AGREEMENT_MESSAGE')}
          />
        </div>
      </>
    );
  };

  const renderUlFields = () => {
    return (
      <>
        <div className={classes.personalDataFormItem}>
          <ComboBoxField
            name={'companyName'}
            loading={loadingSuggestCompany}
            freeSolo
            valueKey={'value'}
            titleKey={'companyName'}
            onChange={handleSuggestCompanySelect}
            onInputChange={handleSuggestCompanyChange}
            data={companyList}
            placeholder={translate('EMPTY_COMPANY_OR_INN')}
            label={translate('COMPANY_NAME')}
            renderOption={renderCompanyOptions}
            readonly={areFieldsReadonly}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_COMPANY');
              }
              return true;
            }}
            filterOptions={(opts, state_) => {
              if (!state_.inputValue) return opts;
              const inputValueLowerCased = state_.inputValue.toLowerCase();
              return opts.filter(
                (opt) =>
                  opt.data.name.toLowerCase().includes(inputValueLowerCased) ||
                  opt.data.inn.toLowerCase().includes(inputValueLowerCased)
              );
            }}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'9999999999'}
            name={'inn'}
            label={translate('INN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 10) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 10,
                  type: translate('INN'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'9999999999999'}
            name={'ogrn'}
            label={translate('OGRN')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 13) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 13,
                  type: translate('OGRN'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'edo'}
            label={translate('EDO_IDENTIFIER')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (value?.length > 255) {
                return translate('NOT_MORE_THAN_SYMBOLS', { amount: 255 });
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <MaskedField
            mask={'999999999'}
            name={'kpp'}
            label={translate('KPP')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value || value.length !== 9) {
                return translate('PERSONAL_ERROR_MESSAGE', {
                  amount: 9,
                  type: translate('KPP'),
                });
              }
              return true;
            }}
            onChanges={handleFormChange}
          />
        </div>

        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'legalAddress'}
            multiline
            rows={3}
            label={translate('LEGAL_ADDRESS')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'postalAddress'}
            multiline
            rows={3}
            InputProps={{ readOnly: Boolean(IsPostalAddressDuplicate || areFieldsReadonly) }}
            label={translate('POSTAL_ADDRESS')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ADDRESS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'IsPostalAddressDuplicate'}
            label={translate('LEGAL_ADDRESS_MATCH')}
            onChange={handleFormChange}
            readOnly={areFieldsReadonly}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('BANK_DETAILS')}
          </Typography>
        </div>

        {renderBankFields()}

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('SIGNATORYS_DATA')}
          </Typography>
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'person'}
            label={translate('FULL_NAME')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_FULLNAME');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'genitiveCasePerson'}
            label={translate('GENITIVE_NAME')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_GENITIVE_NAME');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'position'}
            label={translate('POSITION')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ERROR');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'genitiveCasePosition'}
            label={translate('POSITION_IN_GENITIVE_CASE')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ERROR');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <FormFieldRhfUncontrolled
            name={'actBasis'}
            label={translate('BASIC_CONTRACT_GENITIVE')}
            InputProps={{ readOnly: areFieldsReadonly }}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_FIELD_ACTBASIS');
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('COMPANY_CONTACTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFormItem}>
          <PhoneField
            name={'phone'}
            label={translate('PHONE')}
            required
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.personalDataFormItem}>
          <EmailField
            name={'email'}
            label={translate('EMAIL')}
            required
            InputProps={{ readOnly: areFieldsReadonly }}
            onChange={handleFormChange}
          />
        </div>

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DOCUMENTS')}
          </Typography>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('DOCUMENT_ON_STATE_REGISTRATION')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'egrulFileHash'}
              fileNameFieldName={'egrulFileName'}
              fileUrlFieldName={'egrulFileUrl'}
              fileProgressName={'egrulFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('CERTIFICATE_OF_TAX_REGISTRATION')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'innFileHash'}
              fileNameFieldName={'innFileName'}
              fileUrlFieldName={'innFileUrl'}
              fileProgressName={'innFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('DOCUMENT_CONFIRMING_THE_AUTHORITY_OF_A_LEGAL_ENTITY')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'authorityFileHash'}
              fileNameFieldName={'authorityFileName'}
              fileUrlFieldName={'authorityFileUrl'}
              fileProgressName={'authorityFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>
        <div className={classes.personalDataFileItem}>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
              {`${translate('CARD_WITH_COMPANY_DETAILS')}`}
            </Typography>
            <UploadField
              className={classes.uploadButton}
              label={translate('CHOOSE_FILE')}
              requiredText={translate('FILE_NOT_CHOSEN')}
              fileHashFieldName={'cardFileHash'}
              fileNameFieldName={'cardFileName'}
              fileUrlFieldName={'cardFileUrl'}
              fileProgressName={'cardFileProgressName'}
              onChange={handleDocumentUpload}
              accept={ImagesMIMEType}
              readonly={areFieldsReadonly}
              showViewButton
              required
            />
          </div>
        </div>

        <div className={classes.personalDataFormItem}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('DATA_PROCESSING')}
          </Typography>
        </div>
        <div className={classes.personalDataFormCheckbox}>
          <CheckboxField
            name={'personalDataAgreement'}
            label={translate('PERSONAL_DATA_AGREEMENT')}
            onChange={handleFormChange}
            readOnly={areFieldsReadonly}
            required
          />
          <NotificationMessage
            show={!personalDataAgreement}
            message={translate('PERSONAL_DATA_AGREEMENT_MESSAGE')}
          />
        </div>
      </>
    );
  };

  const renderFieldsByType = () => {
    switch (currentType) {
      case ClientType.Fl:
        return renderFlFields();
      case ClientType.Ip:
        return renderIpFields();
      case ClientType.Ul:
        return renderUlFields();
      case null:
      case undefined: {
        return null;
      }
      default:
        currentType satisfies never;
        sentryCaptureExceptionThrottled(
          Error(
            'ContractPersonalData - found potential inconsistency with server. Got unexpected value for client type.'
          )
        );
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
        return <PageError />;
    }
  };

  const renderModalContentErrors = () => {
    const { showDialog, message } = showErrorDialog;
    if (showDialog) {
      return (
        <>
          <div className={classes.personalDataErrorCenterBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate(message)}
            </Typography>
          </div>
          <div className={classes.controlls}>
            <Button title={translate('CLOSE')} onClick={handleErrorDialogClose} smallHeight />
          </div>
        </>
      );
    }
    return <div />;
  };

  const disabledProps = isSiblingDomainCommercial
    ? {
        optionsClassName: classes.selectDisabled,
        className: classes.selectDisabledWrap,
        iconClassName: classes.selectChevron,
      }
    : {};

  const closeConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const renderConfirmDialogContent = () => {
    return <Typography>{translate('CONTRACT_FORMED_NOTE_03')}</Typography>;
  };

  const getContinueButtonProps = () => {
    if (hasDataBeenAlreadySubmitted) {
      return {
        type: 'button' as const,
        onClick: handleUpdateClientPassportSuccess,
        title: translate('CONTINUE'),
      };
    }

    return { title: translate('SEND_FOR_VERIFICATION'), disabled: !currentType };
  };

  return (
    <ContractLayout
      isLoading={loadingUpdateClientPassport || loadingClientData || loadingUserData}
      childrenHeight={rootRef?.current?.clientHeight}
      formId={formType.PersonalData}
      continueButtonProps={getContinueButtonProps()}
    >
      {loadingClientData || loadingUserData ? (
        <PagePreloader />
      ) : (
        <div ref={rootRef} className={classes.personalDataRoot}>
          <FormProvider {...formMethods}>
            <form id={formType.PersonalData} onSubmit={handleSubmit(handlePersonalDataSubmit)}>
              <fieldset disabled={Boolean(isSiblingDomainCommercial)} className={classes.fieldset}>
                <Typography type={'text2'} color={'tertiary900'}>
                  {translate('ENTER_PAYER_DATA')}
                </Typography>
                <div className={classes.personalDataContent}>
                  <div className={classes.personalDataFormItem}>
                    {isSiblingDomainCommercial || areFieldsReadonly ? (
                      <FormFieldRhfUncontrolled
                        name={'type'}
                        label={translate('LEGAL_STATUS')}
                        value={translate(
                          payerTypeOptionsList.find((payerType) => payerType.value === clientType)
                            ?.titleCode || ''
                        )}
                        InputProps={{ readOnly: true }}
                      />
                    ) : (
                      <SelectField
                        name={'type'}
                        label={translate('LEGAL_STATUS')}
                        translating
                        valueKey={'value'}
                        titleKey={'titleCode'}
                        placeholder={'CHOOSE_STATUS'}
                        defaultValue={legalTypeDefaultValue}
                        data={payerTypeOptionsList}
                        InputProps={{ readOnly: areFieldsReadonly }}
                        onChange={(event) => {
                          handleTypeChange(event.target.value as ClientType);
                        }}
                        disabled={isSiblingDomainCommercial || areFieldsReadonly}
                        {...disabledProps}
                      />
                    )}
                  </div>
                  {renderFieldsByType()}
                </div>
              </fieldset>
            </form>
          </FormProvider>
        </div>
      )}
      <ConfirmDialog
        isBlocked={isChanged.isBlocking}
        onNavigationBlocked={getBlockedPath}
        onSaveChanges={handleSubmit(handlePersonalDataSubmit)}
      />
      <MessageDialog
        isOpen={showErrorDialog.showDialog}
        contentClass={classes.personalDataErrorDialog}
        title={translate(showErrorDialog.title)}
        onCancel={handleErrorDialogClose}
        renderContent={renderModalContentErrors()}
      />
      <MessageDialog
        title={translate('ARE_YOU_SURE')}
        isOpen={isConfirmDialogOpen}
        onCancel={closeConfirmDialog}
        disableClose={loadingUpdateClientPassport}
        renderContent={renderConfirmDialogContent()}
        buttonSuccessProps={{
          titleCode: 'CONFIRM',
          onClick: handleSubmit(handlePersonalDataSubmit),
          loading: loadingUpdateClientPassport,
        }}
        buttonCancelProps={{
          titleCode: 'CANCEL',
          onClick: closeConfirmDialog,
          disabled: loadingUpdateClientPassport,
        }}
      />
    </ContractLayout>
  );
};

export default ContractPersonalData;
