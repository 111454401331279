const hasDateTimeOriginal = (obj: unknown): obj is { DateTimeOriginal: Date } => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (obj as Record<string, unknown>).DateTimeOriginal instanceof Date
  );
};

export const getLastModifiedDateFromExifrData = (obj: unknown) => {
  if (!hasDateTimeOriginal(obj)) {
    return null;
  }

  return obj.DateTimeOriginal;
};
