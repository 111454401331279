import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import { getPrivateRoutesMap } from '@/routes';
import Breadcrumb from '@components/Breadcrumb';
import { IRoute, useRoutes } from '@components/Routes';
import React from 'react';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { getCurrentDomain } from '@/utils';
import { useBreadcrumbsStyles } from './Breadcrumbs.styles';

type Props = {
  children?: React.ReactNode;
};

export const Breadcrumbs = ({ children }: Props) => {
  const classes = useBreadcrumbsStyles();
  const { features } = useDomainFeatures();

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const clientType = getCurrentDomain(userData?.user)?.client?.type;

  const privateRoutesMap = getPrivateRoutesMap({ clientType });

  const {
    route: { excluded = false } = {},
    path: { category = '' },
  } = useRoutes(privateRoutesMap);

  const items = privateRoutesMap.get(category)?.children ?? [];

  const renderItem = (item: IRoute) => (
    <Breadcrumb key={item.path} {...item} path={`/${category}/${item.path}`} />
  );

  const filterRoutes = (item: IRoute) => {
    if (features && item.feature) {
      return isFeatureAvailable(item.feature, features);
    }
    return true;
  };

  if (excluded) {
    return <div />;
  }

  const content = items.filter(filterRoutes).map(renderItem);

  return (
    <div className={classes.root}>
      <div className={classes.content}>{content}</div>
      <div className={classes.actions}>{children}</div>
    </div>
  );
};

export default Breadcrumbs;
