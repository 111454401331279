import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Typography from '@shared/components/Typography';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import Button from '@shared/components/Button';
import MessageDialog from '@shared/components/MessageDialog';
import ComboBoxField from '@shared/components/ComboBoxField';
import ConfirmDialog, { ConfirmAction, useConfirmDialogStatus } from '@components/ConfirmDialog';
import Accordion from '@components/Accordion';
import Flex from '@shared/components/Flex';
import Translate from '@shared/components/Translate';
import { useRoutes } from '@components/Routes';
import { useTranslation } from 'react-i18next';
import {
  AlertCircleIcon,
  ArrowRepeatIcon,
  CheckIcon,
  DownloadIcon,
} from '@shared/assets/images/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_INTEGRATION_DETAILS_QUERY,
  GET_SELECTED_TARIFF_QUERY,
  INCOMING_NUMBERS_QUERY,
  USER_QUERY,
  GET_AMOCRM_REINSTALL_URL_QUERY,
} from '@/client/queries';
import { nanoid } from 'nanoid';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { useNavigate } from 'react-router-dom';
import ControlButtons from '@shared/components/ControlButtons';
import { isObjectEqual } from '@/utils';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import SwitchField from '@shared/components/SwitchField';
import BodyContainer from '@/layouts/BodyContainer';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import client, { omitTypename } from '@components/client/client';
import { PreviewDialog } from '@components/PreviewDialog/PreviewDialog';
import { KLIENTICS_SLIDER_IMAGES } from '@/features/Integrations/IntegrationForm/Klientics/KlienticsCRM.constants';
import { isCertainErrorMessageInApolloErrorErrors } from '@shared/utils/apollo';
import resultify from '@shared/utils/resultify';
import { IntegrationStatus } from '@/client/generated/graphql';
import { PLANFIX_SLIDER_IMAGES } from './Planfix/PlanfixCRM.constants';
import { OMNIDESK_SLIDER_IMAGES } from './Omnidesk/OmnideskCRM.constants';
import { SIMPLE_CALLS_SLIDER_IMAGES } from './SimpleCalls/SimpleCalls.constants';
import IntegrationScenarioTabs from './modules/ScenariosTabs';
import CallsForResponsibleMenu from './modules/CallsForResponsibleMenu';
import ScenarioSettings from './modules/integrationSettings/ScenariosSettings';
import CRMEmployees from './modules/CRMEmployees';
import ScenariosActiveSettingsTab from './modules/integrationSettings/ScenariosActiveSettingsTab';
import { useIntegrationFormStyles } from './IntegrationForm.styles';
import {
  CALL_BACK_LINK,
  crmLiteralNames,
  getGravitelIntegrationList,
  setCloudUrl,
  setSwitchText,
} from './IntegrationForm.constants';
import {
  comparedEmployeeListType,
  completeSettingData,
  IConfirmData,
  IContextStateType,
  IScenarioSettingProps,
  listedObjectKeysType,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingResultType,
  ScenarioSettingsCompleteDataType,
  scenarioSettingsItemType,
  scenarioSettingsType,
  selectedNumbersType,
  settingCommonType,
  settingSiteType,
} from './IntegrationForm.interfaces';
import {
  useIntegrationDialogs,
  useIntegrationHandlers,
  useIntegrationNumbers,
  useIntegrationProps,
  useIntegrationSettings,
} from './hooks';
import { InstallModule } from './modules/InstallModule';
import { RoistatCRMAuthorization } from './Roistat/RoistatCRMAuthorization';

export const IntegrationContext = createContext<IContextStateType | null>(null);

export const IntegrationForm = () => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const {
    path: { subcategory, identifier },
  } = useRoutes();

  const isPreview = subcategory === 'preview-integration';
  const isIntegrationEdit = !(
    subcategory === 'add-integration' || subcategory === 'preview-integration'
  );
  const CRMAPIUrl = process.env.CRM_URL;

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });

  const [amoReinstall] = useLazyQuery(GET_AMOCRM_REINSTALL_URL_QUERY);

  const formMethods = useForm({
    defaultValues: {
      scenarioName: '',
      integrationName: '',
      integrationStatus: false,
      numberList: '',
      crmAddress: '',
      crmName: '',
      crmKey: '',
      cloudAddress: '',
      cloudKey: isIntegrationEdit || isPreview ? '' : nanoid(),
      scenarioNumbers: 'allNumbers',
    },
  });
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const scenarioNumbers = watch('scenarioNumbers');
  const cloudAddressValue = watch('cloudAddress');
  const cloudKeyValue = watch('cloudKey');
  const crmName = watch('crmName');

  const [isLoading, setIsLoading] = useState(false);

  const {
    integrationSettings,
    setIntegrationSettings,

    responsibleSetting,
    setResponsibleSetting,

    scenarioIndex,
    setScenarioIndex,

    isScenarioNameEdit,
    setScenarioNameEdit,

    comparedEmployee,
    setComparedEmployee,

    whereToFindShow,
    setWhereToFindShow,

    isConfigurationLoaded,
    setConfigurationLoaded,
  } = useIntegrationSettings();

  const {
    confirmDeleteIntegrationDialog,
    setConfirmDeleteIntegrationDialog,

    employeesError,
    setEmployeesError,

    dialogState,
    setDialogState,

    scenarioSettingDialog,
    setScenarioSettingDialog,

    isConfirmStatusSwitchShow,
    setConfirmStatusSwitchShow,

    isBackCallDialogShow,
    setBackCallDialogShow,

    isResponsibleSettingError,
    setResponsibleSettingError,

    deleteScenarioDialog,
    setDeleteScenarioDialog,

    callsDisabledDialogShow,
    setCallsDisabledDialogShow,
  } = useIntegrationDialogs();

  const {
    createIntegration,
    updateIntegration,
    deleteIntegration,
    updateIntegrationActivation,
    loadingUpdateIntegrationActivation,
    loadingDeleteIntegration,
  } = useIntegrationHandlers();

  const { blockedPath, blockedState, setBlockedPath, setBlockedState } = useConfirmDialogStatus();
  const [showInitialLoading, setShowInitialLoading] = useState(isIntegrationEdit);

  const [
    getIntegrationDetails,
    { data: currentIntegrationData, loading: loadingIntegration, error: errorIntegration },
  ] = useLazyQuery(GET_INTEGRATION_DETAILS_QUERY, {
    onCompleted: () => {
      setShowInitialLoading(false);
    },
    onError: () => {
      setShowInitialLoading(false);
      setDialogState({
        isOpen: true,
        title: 'SOMETHING_WENT_WRONG',
        message: 'LOADING_INTEGRATION_ERROR',
      });
    },
  });
  const { data: selectedTariff } = useQuery(GET_SELECTED_TARIFF_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const currentDomain = selectedTariff?.getDomain.domain;

  /**
   * This wrapper purpose is to prevent race condition between
   * GET_INTEGRATION_DETAILS_QUERY and INCOMING_NUMBERS_QUERY.
   * INCOMING_NUMBERS_QUERY should be loaded firstly.
   */
  const getIntegrationDetailsWrapped = useCallback(
    async (...params: Parameters<typeof getIntegrationDetails>) => {
      const incomingNumbersData = client.readQuery({ query: INCOMING_NUMBERS_QUERY });
      if (!incomingNumbersData) {
        await resultify(client.query({ query: INCOMING_NUMBERS_QUERY }));
      }
      return getIntegrationDetails(...params);
    },
    [getIntegrationDetails]
  );

  const currentIntegration = currentIntegrationData?.getIntegrationDetails;
  const defaultType = currentIntegration ? currentIntegration.type : IntegrationList.API;
  const user = userData?.user;

  const {
    APIName,
    ApiInformation,
    APILogo,
    PreviewAPILogo,
    integrationId,
    isBitrix24Integration,
    isAmoCRMIntegration,
    isGravitelIntegration,
    isAPIIntegration,
    isScenarioTabsAvailable,
    isEmployeeListAvailable,
    isYClientsIntegration,
    isOmnideskIntegration,
    isPlanfixIntegration,
    isRoistatIntegration,
    isKlienticsIntegration,
    isSettingsAvailable,
    isSimpleCallsIntegration,
    isRetailIntegration,
    isControlsAvailable,
    isTabsHidden,
  } = useIntegrationProps(isIntegrationEdit, defaultType, currentIntegration, comparedEmployee);

  const {
    selectedNumbers,
    setSelectedNumbers,
    selectedNumbersKeys,
    boughtDomainNumbers,
    formatDomainNumbers,
    availableNumbersList,
    responsibleNumbersList,
    isResponsibleNumbersAvailable,
  } = useIntegrationNumbers(scenarioNumbers, currentIntegration, integrationSettings);

  const isCallsDisabled =
    isAmoCRMIntegration &&
    isIntegrationEdit &&
    (!currentIntegration?.params?.calls || currentIntegration?.params?.calls === 'off');

  // Сохранение или обновление данных по интеграции
  const handleIntegrationUpdate = (formData) => {
    if (selectedNumbersKeys.length === 0 && scenarioNumbers !== 'allNumbers') return;
    if ((isAmoCRMIntegration || isBitrix24Integration) && !isEmployeeListAvailable) {
      setEmployeesError(true);
      return;
    }

    let { crmAddress } = formData;
    const { integrationName, cloudKey, crmKey } = formData;

    if (!crmAddress && currentIntegration?.url) {
      crmAddress = currentIntegration.url;
    }

    const scenarioResultData = integrationSettings.reduce(
      (result: scenarioSettingResultType[], scenarioItem) => {
        const areAllNumbersUsed = scenarioItem.scenarioNumbers === 'allNumbers';
        const responsibleSettingItem = scenarioItem.responsibleSetting;
        const scenarioSettingItem = scenarioItem.scenarioSettings;
        const selectedNumbersKeysItem = Object.keys(scenarioItem.selectedNumbers || {});
        let responsibleNumbers = null;
        let responsible = {};
        if (responsibleSettingItem) {
          const { numbers, ...restData } = responsibleSettingItem;
          responsibleNumbers = numbers.map((i: number | string) => String(i));
          responsible = { responsible: restData };
        }
        const numbersList = formatDomainNumbers(
          areAllNumbersUsed
            ? boughtDomainNumbers.map((number) => String(number.id))
            : selectedNumbersKeysItem,
          responsibleNumbers
        );

        const settingResult: scenarioSettingResultType = {
          name: isAPIIntegration || isGravitelIntegration ? 'basic' : scenarioItem.name,
          isAllDomainNumbersUsed: areAllNumbersUsed,
          numbers: areAllNumbersUsed ? numbersList.filter((i) => i.responsible) : numbersList,
          settings: {
            ...responsible,
          },
        };

        if (scenarioSettingItem) {
          settingResult.settings = {
            ...(settingResult.settings as Record<string, unknown>),
            ...omitTypename(scenarioSettingItem),
          };
        }

        result.push(settingResult);
        return result;
      },
      []
    );

    const params = () => {
      if (isGravitelIntegration) {
        return { params: { crmName } };
      }
      if (
        !(isAmoCRMIntegration || isBitrix24Integration || isRetailIntegration) ||
        !comparedEmployee.compared
      )
        return undefined;
      const cEmployeesKeys = Object.keys(comparedEmployee.compared);
      const cEmployeesList = cEmployeesKeys.reduce((result, key) => {
        if (key === 'notmatch') {
          return {
            ...result,
            notmatch: comparedEmployee?.compared?.notmatch,
          };
        }
        const comparedEmployeeItem = comparedEmployee?.compared?.[key] as Record<string, unknown>;
        return {
          ...result,
          [key]: isBitrix24Integration
            ? `${comparedEmployeeItem.crmId}`
            : comparedEmployeeItem.crmId,
        };
      }, {});

      return {
        params: {
          ...currentIntegration?.params,
          employees: cEmployeesList,
        },
      };
    };

    const retailToken = isRetailIntegration ? currentIntegration?.token : undefined;

    const setIntegrationStatus = () => {
      if ((isAmoCRMIntegration || isBitrix24Integration) && !currentIntegration) {
        return IntegrationStatus.Active;
      }
      if (currentIntegration?.status === IntegrationStatus.Draft) {
        return IntegrationStatus.Active;
      }
      return currentIntegration?.status;
    };

    setBlockedState({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });

    setIsLoading(true);
    if (isIntegrationEdit || isBitrix24Integration || isAmoCRMIntegration) {
      const isBitrixOrAmoCRM = isBitrix24Integration || isAmoCRMIntegration;
      const absentValue = isAPIIntegration || isGravitelIntegration ? null : undefined;
      updateIntegration({
        variables: {
          data: {
            id: integrationId || Number(identifier),
            status: setIntegrationStatus(),
            name: integrationName,
            token: isBitrixOrAmoCRM ? undefined : retailToken || cloudKey,
            clientToken: isBitrixOrAmoCRM ? undefined : crmKey || absentValue,
            url: isBitrixOrAmoCRM ? undefined : crmAddress || absentValue,
            scenarios: scenarioResultData,
            ...params(),
          },
        },
      })
        .then((res) => {
          if (isCallsDisabled) {
            setCallsDisabledDialogShow(true);
          } else {
            navigate(blockedPath || '/integrations');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setDialogState({
            isOpen: true,
            title: 'SOMETHING_WENT_WRONG',
            message: 'UPDATE_INTEGRATION_ERROR',
          });
        });
    } else {
      createIntegration({
        variables: {
          data: {
            name: integrationName,
            type: APIName,
            token: cloudKey || undefined,
            clientToken: crmKey || undefined,
            url: crmAddress || undefined,
            scenarios: scenarioResultData,
            ...params(),
          },
        },
      })
        .then(() => {
          if (isCallsDisabled) {
            setCallsDisabledDialogShow(true);
          } else {
            navigate(blockedPath || '/integrations');
          }
        })
        .catch((error) => {
          setIsLoading(false);

          let dialogTitle = 'SOMETHING_WENT_WRONG';
          let dialogMessage = 'CREATE_INTEGRATION_ERROR';

          if (isCertainErrorMessageInApolloErrorErrors(error, 'url must be unique')) {
            dialogTitle = 'ERROR';
            dialogMessage = 'CREATE_INTEGRATION_URL_ERROR';
          }
          setDialogState({
            isOpen: true,
            title: dialogTitle,
            message: dialogMessage,
          });
        });
    }
  };

  useEffect(() => {
    if (isEmployeeListAvailable) {
      setEmployeesError(false);
    }
  }, [isEmployeeListAvailable, setEmployeesError]);

  useEffect(() => {
    setIntegrationSettings((prevState) => {
      if (prevState[scenarioIndex].currentIndex === scenarioIndex) {
        const updatedScenario = [...prevState];
        updatedScenario[scenarioIndex] = {
          currentIndex: prevState[scenarioIndex].currentIndex,
          name: prevState[scenarioIndex].name,
          responsibleSetting: responsibleSetting.data,
          scenarioSettings: scenarioSettingDialog.data,
          selectedNumbers,
          scenarioNumbers,
        };
        return updatedScenario;
      }
      return prevState;
    });
  }, [
    selectedNumbers,
    responsibleSetting.data,
    scenarioSettingDialog.data,
    scenarioNumbers,
    scenarioIndex,
    setIntegrationSettings,
  ]);

  useEffect(() => {
    if (isIntegrationEdit) {
      if (!currentIntegration) {
        getIntegrationDetailsWrapped({
          variables: { id: integrationId || Number(identifier) },
        });
      } else {
        setShowInitialLoading(false);
      }
    }
  }, [
    currentIntegration,
    isIntegrationEdit,
    getIntegrationDetails,
    identifier,
    integrationId,
    getIntegrationDetailsWrapped,
  ]);

  useEffect(() => {
    if (user) {
      const domain = getCurrentDomain(user)?.domain;
      const cloudUrlAddress = setCloudUrl(APIName, domain, CRMAPIUrl);
      setValue('cloudAddress', cloudUrlAddress);
    }
  }, [setValue, CRMAPIUrl, isIntegrationEdit, user, APIName]);

  useEffect(() => {
    if (
      currentIntegration &&
      boughtDomainNumbers &&
      selectedNumbersKeys.length === 0 &&
      !isConfigurationLoaded
    ) {
      setValue('integrationName', currentIntegration.name || '');
      setValue('crmAddress', currentIntegration.url || '');
      if (!isAmoCRMIntegration && !isBitrix24Integration) {
        setValue('crmKey', currentIntegration.clientToken || '');
        setValue('cloudKey', currentIntegration.token);
      }
      if (currentIntegration.params) {
        setValue('crmName', currentIntegration.params.crmName);
      }
      setValue(
        'integrationStatus',
        currentIntegration.status === IntegrationStatus.Active ||
          currentIntegration.status === IntegrationStatus.Draft
      );

      currentIntegration.scenarios.forEach((dbScenarioItem, dbScenarioIndex: number) => {
        const isAllNumbersValue = dbScenarioItem.isAllDomainNumbersUsed
          ? 'allNumbers'
          : 'selectedNumbers';
        let numbersKeyList: selectedNumbersType = {};
        let respSettingObject: IConfirmData | null = null;
        let scenarioSettings: ScenarioSettingsCompleteDataType = {};
        if (!dbScenarioItem.isAllDomainNumbersUsed) {
          dbScenarioItem.numbers.forEach((numberInScenario) => {
            const numberItem = boughtDomainNumbers.find((number) => {
              return number.id === numberInScenario.numberInfo.id;
            });
            if (numberItem) {
              numbersKeyList = {
                ...numbersKeyList,
                [numberItem.id]: numberItem,
              };
            }
          });
        }
        if (dbScenarioItem.settings?.responsible) {
          const numbersList = dbScenarioItem.numbers
            .filter((number) => number.responsible)
            .map((number) => number.domainNumberId);
          const respSettings = dbScenarioItem.settings?.responsible;
          respSettingObject = {
            numbers: numbersList,
            playInfo: respSettings.playInfo,
            next: respSettings.next,
            nextId: respSettings.nextId,
            message: respSettings.message,
            fileHash: respSettings.fileHash,
            fileName: respSettings.fileName,
            dialTimeout: respSettings.dialTimeout,
          };
        }
        if (dbScenarioItem.settings?.incoming) {
          scenarioSettings = {
            ...scenarioSettings,
            [scenarioDirectionEnum.Incoming]: dbScenarioItem.settings?.incoming,
          };
        }
        if (dbScenarioItem.settings?.outgoing) {
          scenarioSettings = {
            ...scenarioSettings,
            [scenarioDirectionEnum.Outgoing]: dbScenarioItem.settings?.outgoing,
          };
        }
        if (dbScenarioItem.settings?.common) {
          scenarioSettings = {
            ...scenarioSettings,
            [scenarioDirectionEnum.Common]: dbScenarioItem.settings?.common,
          };
        }
        if (dbScenarioItem.settings?.retailCrm) {
          scenarioSettings = {
            ...scenarioSettings,
            [scenarioDirectionEnum.RetailCrm]: dbScenarioItem.settings?.retailCrm,
          };
        }
        if (scenarioIndex === dbScenarioIndex) {
          setSelectedNumbers(numbersKeyList);
          setScenarioSettingDialog((prevState) => ({
            ...prevState,
            data: scenarioSettings,
          }));
          setResponsibleSetting({
            isOpen: false,
            data: respSettingObject,
          });
          setValue('scenarioNumbers', isAllNumbersValue);
        }
        setIntegrationSettings((prevState) => {
          const updatedScenario = [...prevState];
          updatedScenario[dbScenarioIndex] = {
            currentIndex: dbScenarioIndex,
            name: dbScenarioItem.name || `${translate('SCENARIO')} ${dbScenarioIndex + 1}`,
            responsibleSetting: respSettingObject,
            selectedNumbers: numbersKeyList,
            scenarioNumbers: isAllNumbersValue,
            scenarioSettings: Object.keys(scenarioSettings).length ? scenarioSettings : null,
          };
          return updatedScenario;
        });
      });
      setConfigurationLoaded(true);
    }
  }, [
    setValue,
    selectedNumbersKeys.length,
    currentIntegration,
    boughtDomainNumbers,
    scenarioIndex,
    translate,
    isConfigurationLoaded,
    setConfigurationLoaded,
    setIntegrationSettings,
    setSelectedNumbers,
    setScenarioSettingDialog,
    setResponsibleSetting,
    isAmoCRMIntegration,
    isBitrix24Integration,
  ]);

  useEffect(() => {
    if (blockedState.action === ConfirmAction.Cancel && !blockedState.isBlocking) {
      navigate('/integrations');
    }
  }, [blockedState, navigate]);

  useEffect(() => {
    if (isScenarioNameEdit) {
      setValue('scenarioName', integrationSettings[scenarioIndex].name);
    }
  }, [isScenarioNameEdit, setValue, integrationSettings, scenarioIndex]);

  function onScenarioTabChange() {
    setScenarioSettingDialog((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    setResponsibleSetting((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  function onPageDataChange() {
    if (!blockedState.isBlocking) {
      setBlockedState({
        isBlocking: true,
        action: ConfirmAction.Edit,
      });
    }
  }

  function handleScenarioSettingChanged(settingData: completeSettingData) {
    const { direction, type, status, data } = settingData || {};
    setScenarioSettingDialog((prevState) => {
      const prevStateData = prevState.data || {};
      let scenarioSettingData;
      if (direction === scenarioDirectionEnum.Common) {
        scenarioSettingData = {
          ...prevStateData,
          [direction]: {
            ...(data as settingCommonType),
          },
        };
      } else if (direction === scenarioDirectionEnum.RetailCrm) {
        scenarioSettingData = {
          ...prevStateData,
          [direction]: {
            ...(data as settingSiteType),
          },
        };
      } else {
        const prevStateDirection: listedObjectKeysType =
          prevStateData[direction as keyof ScenarioSettingsCompleteDataType] || undefined;

        const prevStateType: listedObjectKeysType = prevStateDirection
          ? prevStateDirection[type]
          : undefined;

        scenarioSettingData = {
          ...prevStateData,
          [direction]: {
            ...prevStateDirection,
            [type]: {
              ...prevStateType,
              [status]: data,
            },
          },
        };
      }
      return {
        isOpen: false,
        scenarioType: null,
        data: scenarioSettingData,
      };
    });
    onPageDataChange();
  }

  function handleCopyText(text: string) {
    navigator.clipboard.writeText(text);
  }

  function handleIntegrationStatusSwitch() {
    setConfirmStatusSwitchShow(false);
    updateIntegrationActivation({
      variables: {
        data: {
          id: Number(identifier),
          activation: currentIntegration?.status !== IntegrationStatus.Active,
        },
      },
    })
      .then((res) => {
        getIntegrationDetailsWrapped({
          variables: { id: Number(identifier) },
        });
      })
      .catch((error) => {
        setDialogState({
          isOpen: true,
          title: 'SOMETHING_WENT_WRONG',
          message: 'INTEGRATION_STATUS_SWITCH_ERROR',
        });
      });
  }

  function handleIntegrationSwitchAction() {
    if (currentIntegration && isIntegrationEdit) {
      if (currentIntegration.status === IntegrationStatus.Active) {
        setConfirmStatusSwitchShow(true);
      } else {
        handleIntegrationStatusSwitch();
      }
    }
  }

  function handleDeleteIntegration() {
    setBlockedState({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    deleteIntegration({
      variables: {
        data: {
          id: Number(identifier),
        },
      },
    })
      .then((res) => {
        navigate('/integrations');
      })
      .catch(() => {
        setDialogState({
          isOpen: true,
          title: 'SOMETHING_WENT_WRONG',
          message: 'DELETE_INTEGRATION_ERROR',
        });
      });
  }

  function handleAmoReinstallClick() {
    if (!currentIntegration?.id) return;
    amoReinstall({
      variables: {
        id: currentIntegration?.id,
      },
    }).then((res) => {
      if (res?.data?.getAmoReInstallURL) {
        window.location.href = res.data.getAmoReInstallURL;
      }
    });
  }

  function handleEditCancel() {
    setBlockedState({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  function handleShowDeleteScenarioDialog(deletedIndex: number) {
    setDeleteScenarioDialog({
      isOpen: true,
      name: integrationSettings[deletedIndex].name || '',
      deletedIndex,
    });
  }

  function handleCloseDeleteScenarioDialog() {
    setDeleteScenarioDialog({
      isOpen: false,
      name: '',
      deletedIndex: 0,
    });
  }

  function handleCloseDialog() {
    setDialogState({
      isOpen: false,
      title: '',
      message: '',
    });
  }

  function handleResponsibleSettingClose() {
    setResponsibleSetting((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  function handleResponsibleSettingFinish(data: IConfirmData | null) {
    onPageDataChange();
    setResponsibleSetting({
      isOpen: false,
      data,
    });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleScenarioNumbersChange(data: selectedNumbersType) {
    setSelectedNumbers(data);
    onPageDataChange();
  }

  function handleEditScenario(editType: scenarioSettingsItemType) {
    const isEmployeeComparedList = () => {
      if (comparedEmployee.compared) {
        const comparedList = Object.keys(comparedEmployee.compared);
        if (comparedList.filter((i) => i !== 'notmatch').length > 0) {
          return true;
        }
      }
      return false;
    };

    if (editType.edit === scenarioSettingsType.Responsible) {
      if (isResponsibleNumbersAvailable || responsibleSetting.data) {
        if (scenarioSettingDialog.isOpen) {
          setScenarioSettingDialog((prevState) => ({
            ...prevState,
            isOpen: false,
          }));
        }
        setResponsibleSetting((prevState) => ({ ...prevState, isOpen: true }));
      } else {
        setResponsibleSettingError(true);
      }
    } else if (isEmployeeComparedList()) {
      if (responsibleSetting.isOpen) {
        setResponsibleSetting((prevState) => ({ ...prevState, isOpen: false }));
      }
      setScenarioSettingDialog((prevState) => ({
        ...prevState,
        isOpen: true,
        scenarioType: editType,
      }));
    } else {
      setDialogState({
        isOpen: true,
        title: 'ERROR',
        message: 'COMPARE_EMPLOYEE_REQUIRED',
      });
    }
  }

  function handleScenarioSettingClose() {
    setScenarioSettingDialog((prevState) => ({
      ...prevState,
      isOpen: false,
      scenarioType: null,
    }));
  }

  function handleScenarioNameEditBlur(tabValue: string | undefined) {
    setIntegrationSettings((prevState) => {
      const updatedScenario = [...prevState];
      updatedScenario[scenarioIndex].name =
        tabValue !== '' ? tabValue || '' : `${translate('SCENARIO')} ${scenarioIndex + 1}`;
      return updatedScenario;
    });
    setScenarioNameEdit(false);
  }

  function handleClickScenarioTabError() {
    const errorMessageKey =
      selectedNumbersKeys.length === 0 && scenarioNumbers !== 'allNumbers'
        ? 'CREATE_SCENARIO_ERROR_CHOOSE_NUMBER'
        : 'CREATE_SCENARIO_ERROR_MESSAGE';
    setDialogState({
      isOpen: true,
      title: 'CREATE_SCENARIO_ERROR',
      message: errorMessageKey,
    });
  }

  function handleClickScenarioTab(
    tabIndex: number,
    tabEvent: string,
    settings?: IScenarioSettingProps[]
  ) {
    if (tabEvent === 'edit' && !isScenarioNameEdit) {
      setScenarioNameEdit(true);
    } else {
      setScenarioIndex(tabIndex);

      if (tabEvent === 'new') {
        setIntegrationSettings((prevState) => {
          const updatedScenario = [...prevState];
          updatedScenario.push({
            currentIndex: tabIndex,
            name: `${translate('SCENARIO')} ${tabIndex + 1}`,
          });
          return updatedScenario;
        });
        setSelectedNumbers({});
        setResponsibleSetting({
          isOpen: false,
          data: null,
        });
        setScenarioSettingDialog({
          isOpen: false,
          scenarioType: null,
          data: null,
        });
        setValue('scenarioNumbers', 'selectedNumbers');

        return;
      }

      if (tabEvent === 'switch') {
        const settingsOrigin = settings || integrationSettings;
        setSelectedNumbers(settingsOrigin[tabIndex].selectedNumbers || {});
        setResponsibleSetting({
          isOpen: false,
          data: settingsOrigin[tabIndex].responsibleSetting || null,
        });
        setScenarioSettingDialog({
          isOpen: false,
          scenarioType: null,
          data: settingsOrigin[tabIndex].scenarioSettings || null,
        });
        setValue('scenarioNumbers', settingsOrigin[tabIndex].scenarioNumbers || 'allNumbers');
      }
    }
  }

  function handleDeleteScenario(deletedIndex: number) {
    let scenarioNewIndex = 0;
    let updatedSettings: IScenarioSettingProps[] | undefined = [];
    setIntegrationSettings((prevState) => {
      updatedSettings = [...prevState];
      updatedSettings.splice(deletedIndex, 1);
      scenarioNewIndex = updatedSettings.length - 1;
      updatedSettings = updatedSettings.map((scenario, index) => ({
        ...scenario,
        currentIndex: index,
      }));
      return updatedSettings;
    });
    handleClickScenarioTab(scenarioNewIndex, 'switch', updatedSettings);
    handleCloseDeleteScenarioDialog();
  }

  function handleWhereToFindClick() {
    setWhereToFindShow(true);
  }

  function handleCompareEmployeeChange(
    comparedList: comparedEmployeeListType,
    rawList: rawDataListType
  ) {
    if (
      !isObjectEqual(comparedEmployee.compared, comparedList) ||
      !isObjectEqual(comparedEmployee.raw, rawList)
    ) {
      if (comparedEmployee.compared !== null) {
        onPageDataChange();
      }
      setComparedEmployee({ compared: comparedList, raw: rawList });
    }
  }

  function handleCancelStatusChange() {
    setConfirmStatusSwitchShow(false);
    setValue('integrationStatus', true);
  }

  function handleCloseAttention() {
    navigate(blockedPath || '/integrations');
  }

  const renderIdentifier = () => {
    if (ApiInformation.sideLogo) {
      return <APILogo className={classes.integrationIdentifierLogo} />;
    }
    return (
      <div className={classes.integrationIdentifierClampText}>
        <Typography type={'text1'} color={'link600'}>
          {crmName as string}
        </Typography>
      </div>
    );
  };

  const renderGravitelIntegrationCRMNameField = () => {
    if (isGravitelIntegration) {
      if (isIntegrationEdit) {
        return (
          <div className={classes.integrationAuthFieldBlock}>
            <FormFieldRhfUncontrolled
              name={'crmName'}
              label={translate('YOUR_CRM')}
              InputProps={{ readOnly: true }}
            />
          </div>
        );
      }
      return (
        <div className={classes.integrationAuthFieldBlock}>
          <ComboBoxField
            name={'crmName'}
            freeSolo
            valueKey={'value'}
            titleKey={'crmName'}
            onChange={onPageDataChange}
            data={getGravitelIntegrationList()}
            label={translate('YOUR_CRM')}
            validate={(value: string) => {
              if (!value) {
                return translate('EMPTY_CRM_NAME') as string;
              }
              return true;
            }}
          />
        </div>
      );
    }
    return null;
  };

  function getPreviewData() {
    const components = {
      t: <Typography color={'tertiary900'} type={'text3'} />,
      b: <Typography color={'tertiary900'} type={'default'} bold />,
    };

    if (isKlienticsIntegration) {
      return KLIENTICS_SLIDER_IMAGES.map(({ title, img }, index) => ({
        title,
        img,
      }));
    }

    if (isSimpleCallsIntegration) {
      return SIMPLE_CALLS_SLIDER_IMAGES.map(({ title, img }, index) => ({
        title,
        img,
      }));
    }

    if (isPlanfixIntegration) {
      return PLANFIX_SLIDER_IMAGES.map(({ title, img }, index) => ({
        title: {
          key: title,
          components,
        },
        img,
      }));
    }

    if (isOmnideskIntegration) {
      return OMNIDESK_SLIDER_IMAGES.map(({ title, img }) => ({
        title: {
          key: title,
          components,
        },
        img,
      }));
    }

    return [];
  }

  const renderMessages = () => (
    <>
      <MessageDialog
        isOpen={confirmDeleteIntegrationDialog}
        contentClass={classes.integrationDialog}
        title={translate('ARE_YOU_SURE')}
        message={translate(
          isYClientsIntegration || isKlienticsIntegration || isSimpleCallsIntegration
            ? 'DELETE_INTEGRATION_MESSAGE_WITH_PARAMS'
            : 'DELETE_INTEGRATION_MESSAGE',
          {
            service: isSimpleCallsIntegration ? translate('WITH_SERVICE') : '',
            crmName: translate(crmLiteralNames[APIName] || ''),
          }
        )}
        onCancel={() => setConfirmDeleteIntegrationDialog(false)}
        renderControls={
          <ControlButtons
            confirmColor={'error'}
            confirmTitle={'REMOVE'}
            cancelTitle={'LEAVE'}
            cancelVariant="secondary"
            flexDirection={'row-reverse'}
            justifyContent={'start'}
            onConfirmClick={() => handleDeleteIntegration()}
            onCancelClick={() => setConfirmDeleteIntegrationDialog(false)}
            loading={loadingDeleteIntegration}
            small
          />
        }
      />
      <MessageDialog
        isOpen={isConfirmStatusSwitchShow}
        contentClass={classes.integrationDialog}
        title={translate('ARE_YOU_SURE')}
        message={translate('INTEGRATION_STATUS_CHANGE_TEXT')}
        onCancel={() => handleCancelStatusChange()}
        renderControls={
          <ControlButtons
            confirmTitle={'SWITCH_OFF'}
            cancelTitle={'LEAVE'}
            cancelVariant="secondary"
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            onConfirmClick={() => handleIntegrationStatusSwitch()}
            onCancelClick={() => handleCancelStatusChange()}
            loading={loadingUpdateIntegrationActivation}
            small
          />
        }
      />
      <MessageDialog
        isOpen={dialogState.isOpen}
        contentClass={classes.integrationDialog}
        title={translate(dialogState.title)}
        message={translate(dialogState.message)}
        onCancel={handleCloseDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseDialog} smallHeight />
        }
      />
      <MessageDialog
        isOpen={deleteScenarioDialog.isOpen}
        contentClass={classes.integrationDeleteScenarioDialog}
        title={translate('ARE_YOU_SURE')}
        onCancel={handleCloseDeleteScenarioDialog}
        renderContent={
          <>
            <div className={classes.integrationDeleteScenarioMessage}>
              <Translate
                i18nKey={'INTEGRATION_SCENARIOS_DELETE_MESSAGE'}
                values={{ name: `"${deleteScenarioDialog.name}"` }}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  b: <Typography type={'text3'} color={'link600'} />,
                  n: <br />,
                }}
              />
            </div>
            <ControlButtons
              confirmTitle={'REMOVE'}
              cancelTitle={'LEAVE'}
              confirmColor={'error'}
              cancelVariant="secondary"
              justifyContent={'start'}
              flexDirection={'row-reverse'}
              onConfirmClick={() => handleDeleteScenario(deleteScenarioDialog.deletedIndex)}
              onCancelClick={() => handleCloseDeleteScenarioDialog()}
              small
            />
          </>
        }
      />
      <MessageDialog
        isOpen={isResponsibleSettingError}
        contentClass={classes.integrationResponsibleErrorDialog}
        title={translate('CREATE_RESPONSIBLE_SETTING_ERROR')}
        onCancel={() => setResponsibleSettingError(false)}
        renderContent={
          <>
            <div className={classes.integrationResponsibleError}>
              <Translate
                i18nKey={'CREATE_RESPONSIBLE_SETTING_MESSAGE_TITLE'}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  n: <br />,
                }}
              />
            </div>
            <ul className={classes.integrationResponsibleUl}>
              <li>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('CREATE_RESPONSIBLE_SETTING_NUMBER_TIP_01')}
                </Typography>
              </li>
              <li>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('CREATE_RESPONSIBLE_SETTING_NUMBER_TIP_02')}
                </Typography>
              </li>
            </ul>
          </>
        }
        renderControls={
          <Button
            title={translate('CLOSE')}
            onClick={() => setResponsibleSettingError(false)}
            smallHeight
          />
        }
      />
      <MessageDialog
        isOpen={isBackCallDialogShow}
        contentClass={classes.integrationResponsibleErrorDialog}
        title={translate('BACK_CALL')}
        onCancel={() => setBackCallDialogShow(false)}
        renderContent={
          <>
            <div className={classes.integrationResponsibleError}>
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate('INSTRUCTION_BACK_CALL')}
              </Typography>
              <Typography type={'text3'} color={'link600'} bold>
                <a href={CALL_BACK_LINK} target={'_blank'} rel={'noreferrer'}>
                  {translate('INSTRUCTION_BACK_LINK')}
                </a>
              </Typography>
            </div>
            <div className={classes.integrationBackCallContainer}>
              <Typography type={'text4'} color={'tertiary900'}>
                {translate('INSTRUCTION_BACK_CALL_DESCRIPTION')}
              </Typography>
            </div>
          </>
        }
        renderControls={
          <Button
            title={translate('CLOSE')}
            onClick={() => setBackCallDialogShow(false)}
            smallHeight
          />
        }
      />
      {isCallsDisabled && (
        <MessageDialog
          isOpen={callsDisabledDialogShow}
          contentClass={classes.integrationDialog}
          title={translate('PAY_ATTENTION')}
          onCancel={handleCloseAttention}
          renderContent={
            <>
              <div className={classes.integrationResponsibleError}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('INTEGRATION_CALLS_DISABLED_MESSAGE_01')}
                </Typography>
              </div>
              <div className={classes.integrationResponsibleError}>
                <Typography
                  className={classes.integrationDialogWrappedText}
                  type={'text3'}
                  color={'tertiary900'}
                >
                  {translate('INTEGRATION_CALLS_DISABLED_MESSAGE_02')}
                </Typography>
              </div>
            </>
          }
          renderControls={
            <Button title={translate('CLOSE')} onClick={handleCloseAttention} smallHeight />
          }
        />
      )}
      <PreviewDialog
        isOpen={whereToFindShow}
        onCancel={() => setWhereToFindShow(false)}
        data={getPreviewData()}
      />
      <ConfirmDialog
        isBlocked={blockedState.isBlocking}
        onNavigationBlocked={getBlockedPath}
        onSaveChanges={handleSubmit(handleIntegrationUpdate)}
      />
    </>
  );

  const renderAuthorizationBlock = () => {
    const { url = undefined, token = undefined } = currentIntegration || {};

    if (isRoistatIntegration) {
      return <RoistatCRMAuthorization integrationId={integrationId} url={url} />;
    }

    if (isBitrix24Integration || isYClientsIntegration || isRetailIntegration) {
      return (
        <div className={classes.integrationAuthFieldBlock}>
          <FormFieldRhfUncontrolled
            label={translate('YOUR_CRM_ADDRESS')}
            value={isYClientsIntegration ? token : url}
            name={'installedUrl'}
            InputProps={{ readOnly: true }}
          />
        </div>
      );
    }

    if (isAmoCRMIntegration) {
      return (
        <div className={classes.integrationAuthFieldBlock}>
          <Flex direction={'row'} justifyContent={'spaceEvenly'} alignItems={'flexEnd'}>
            <FormFieldRhfUncontrolled
              label={translate('YOUR_CRM_ADDRESS')}
              value={isYClientsIntegration ? token : url}
              name={'installedUrl'}
              InputProps={{ readOnly: true }}
            />
            <Button
              onClick={handleAmoReinstallClick}
              variant="secondary"
              className={classes.reAuthorization}
              size={'small'}
              titleNative={translate('AMOCRM_REAUTHORIZATION')}
            >
              <ArrowRepeatIcon className={classes.authIcon} />
            </Button>
          </Flex>
        </div>
      );
    }

    const isSimpleIntegration = isAPIIntegration || isGravitelIntegration;

    return (
      <>
        <div className={classes.integrationAuthTitleBlock}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('AUTH_INTEGRATION_TITLE')}
          </Typography>
        </div>
        {renderGravitelIntegrationCRMNameField()}
        <div className={classes.integrationAuthFieldBlock}>
          {!integrationId &&
          (isOmnideskIntegration || isPlanfixIntegration || isSimpleCallsIntegration) ? (
            <Button
              onClick={handleWhereToFindClick}
              clear
              className={classes.integrationAuthWhereToFindButton}
            >
              <Typography type={'text4'} color={'link600'} underline>
                {`${translate('WHERE_SEARCH')}?`}
              </Typography>
            </Button>
          ) : null}
          <FormFieldRhfUncontrolled
            name={'crmAddress'}
            label={translate('ADDRESS_CRM')}
            onChange={onPageDataChange}
            validate={(crmAddress: string) => {
              const urlPattern =
                /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/gm;
              if (!isSimpleIntegration && !crmAddress) {
                return translate('EMPTY_ADDRESS') as string;
              }
              if (crmAddress && !urlPattern.test(crmAddress)) {
                return translate('INVALID_ADDRESS') as string;
              }
              if (crmAddress === cloudAddressValue) {
                return translate('SAME_INVALID_ADDRESS') as string;
              }
              if (
                isSimpleIntegration &&
                currentDomain &&
                crmAddress.match(`^https?://${currentDomain}/`)
              ) {
                return translate('INVALID_ADDRESS');
              }
              return true;
            }}
          />
        </div>
        <div className={classes.integrationAuthFieldBlock}>
          {!integrationId &&
          (isOmnideskIntegration ||
            isPlanfixIntegration ||
            isKlienticsIntegration ||
            isSimpleCallsIntegration) ? (
            <Button
              onClick={handleWhereToFindClick}
              clear
              className={classes.integrationAuthWhereToFindButton}
            >
              <Typography type={'text4'} color={'link600'} underline>
                {`${translate('WHERE_SEARCH')}?`}
              </Typography>
            </Button>
          ) : null}
          <FormFieldRhfUncontrolled
            name={'crmKey'}
            label={translate('CRM_KEY')}
            onChange={onPageDataChange}
            validate={(crmKey: string) => {
              if (!crmKey && !isSimpleIntegration) {
                return translate('EMPTY_KEY') as string;
              }
              if (crmKey && crmKey.length < 5) {
                return translate('INVALID_KEY_LENGTH') as string;
              }
              if (crmKey === cloudKeyValue) {
                return translate('SAME_INVALID_KEY') as string;
              }
              return true;
            }}
          />
        </div>
        <div className={classes.integrationAuthTitleBlock}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('AUTH_ADDRESS_KEY_TITLE')}
          </Typography>
        </div>
        <div className={classes.integrationAuthFieldBlock}>
          <FormFieldRhfUncontrolled
            name={'cloudAddress'}
            label={translate('CLOUD_PBX_ADDRESS')}
            InputProps={{ readOnly: true }}
          />
          <Flex justifyContent={'flexEnd'}>
            <Button clear onClick={() => handleCopyText(cloudAddressValue)}>
              <Typography color={'link500'} type={'text4'}>
                {translate('COPY')}
              </Typography>
            </Button>
          </Flex>
        </div>
        <div className={classes.integrationAuthFieldBlock}>
          <FormFieldRhfUncontrolled
            name={'cloudKey'}
            label={translate('CLOUD_AUTH_KEY')}
            InputProps={{ readOnly: true }}
          />
          <Flex direction={'column'} justifyContent={'flexEnd'} alignItems={'flexEnd'}>
            <Button clear onClick={() => handleCopyText(cloudKeyValue)}>
              <Typography color={'link500'} type={'text4'}>
                {translate('COPY')}
              </Typography>
            </Button>
          </Flex>
        </div>
      </>
    );
  };

  const renderIntegrationNameField = () => {
    if (
      !integrationId &&
      (isBitrix24Integration || isAmoCRMIntegration || isYClientsIntegration || isRetailIntegration)
    ) {
      return null;
    }
    return (
      <FormFieldRhfUncontrolled
        name={'integrationName'}
        className={classes.integrationBottomField}
        label={translate('INTEGRATION_NAME')}
        onChange={onPageDataChange}
        inputProps={{
          maxLength: 65,
        }}
        validate={(integrationName: string) => {
          if (!integrationName) {
            return translate('EMPTY_INTEGRATION_NAME') as string;
          }
          return true;
        }}
      />
    );
  };

  const renderAutorizationSection = () => {
    if (isPreview) return null;
    return (
      <Accordion
        title={translate('AUTHORIZATION')}
        defaultExpand={!isIntegrationEdit || currentIntegration?.status === IntegrationStatus.Draft}
        forceExpand={!!(errors.crmAddress || errors.crmKey)}
      >
        {renderAuthorizationBlock()}
      </Accordion>
    );
  };

  const renderEmployeeList = () => {
    if ((isBitrix24Integration || isAmoCRMIntegration || isRetailIntegration) && integrationId) {
      return (
        <CRMEmployees
          onChange={handleCompareEmployeeChange}
          APIName={APIName}
          integrationId={integrationId}
          rawComparedEmployee={currentIntegration?.params?.employees}
          employeesError={employeesError}
          integrationStatus={currentIntegration?.status}
        />
      );
    }
    return null;
  };

  const renderSettingCallsForResponsible = () => {
    if (!ApiInformation.configuration?.responsible) return null;
    return (
      <CallsForResponsibleMenu
        isOpen={responsibleSetting.isOpen}
        onClose={() => handleResponsibleSettingClose()}
        onConfirm={handleResponsibleSettingFinish}
      />
    );
  };

  const renderSettingsContent = () => (
    <Accordion
      title={
        ApiInformation.configuration?.settingsTitle &&
        translate(ApiInformation.configuration.settingsTitle, {
          amount: integrationSettings.length || 1,
        })
      }
      contentRootClass={classes.employeeRoot}
      defaultExpand
    >
      <IntegrationScenarioTabs
        onTabBlur={handleScenarioNameEditBlur}
        onTabClick={handleClickScenarioTab}
        onError={handleClickScenarioTabError}
      />
      <ScenariosActiveSettingsTab
        onNumbersChange={handleScenarioNumbersChange}
        onPageDataChange={onPageDataChange}
        onScenarioEdit={handleEditScenario}
        onScenarioDelete={handleShowDeleteScenarioDialog}
        onTabChange={onScenarioTabChange}
        onOtherScenarioEdit={() => setBackCallDialogShow(true)}
      />
    </Accordion>
  );

  const renderControls = () => (
    <>
      <div className={classes.integrationBottomLeftControls}>
        {isControlsAvailable && !isPreview ? (
          <ControlButtons
            confirmTitle={'SAVE_CHANGES'}
            cancelTitle={'CANCEL'}
            form={'integration-update'}
            onCancelClick={() => handleEditCancel()}
            rootConfirmStyles={classes.confirmBtnLarge}
            loading={isLoading}
            confirmDisable={isLoading || responsibleSetting.isOpen}
          />
        ) : null}
      </div>
      <div>
        {isIntegrationEdit && (
          <Button
            title={translate('DELETE_INTEGRATION')}
            disabled={loadingDeleteIntegration}
            className={classes.textUnderline}
            onClick={() => setConfirmDeleteIntegrationDialog(true)}
            variant={'tertiary'}
          />
        )}
      </div>
    </>
  );

  const integrationFormValue = useMemo(
    () => ({
      APIName,
      integrationId,
      selectedNumbers,
      responsibleNumbersList,
      isIntegrationEdit,
      isPreview,
      scenarioIndex,
      integrationSettings,
      isScenarioTabsAvailable,
      isTabsHidden,
      isScenarioNameEdit,
      scenarioNumbers,
      isSettingsAvailable,
      isEmployeeListAvailable,
      availableDomainNumbers: availableNumbersList,
      newTabAvailable: Boolean(
        availableNumbersList.length &&
          scenarioNumbers !== 'allNumbers' &&
          selectedNumbersKeys.length
      ),
      integrationName: currentIntegration ? currentIntegration.name : '',
      integrationStatus: currentIntegration?.status,
      scenarioType: scenarioSettingDialog.scenarioType,
      scenarioData: scenarioSettingDialog.data,
      responsibleData: responsibleSetting.data,
      comparedEmployee: comparedEmployee.compared,
      rawEmployee: comparedEmployee.raw,
    }),
    [
      APIName,
      availableNumbersList,
      comparedEmployee.compared,
      comparedEmployee.raw,
      currentIntegration,
      integrationId,
      integrationSettings,
      isEmployeeListAvailable,
      isIntegrationEdit,
      isPreview,
      isScenarioNameEdit,
      isScenarioTabsAvailable,
      isSettingsAvailable,
      isTabsHidden,
      responsibleNumbersList,
      responsibleSetting.data,
      scenarioIndex,
      scenarioNumbers,
      scenarioSettingDialog.data,
      scenarioSettingDialog.scenarioType,
      selectedNumbers,
      selectedNumbersKeys.length,
    ]
  );

  const renderIntegrationNotification = () => {
    if (isAmoCRMIntegration && isIntegrationEdit) {
      switch (currentIntegration?.params?.calls) {
        case 'makecall': {
          return (
            <>
              <CheckIcon className={classes.checkIcon} />
              <Typography>{translate('AMOCRM_CALLS_ON')}</Typography>
            </>
          );
        }
        case 'webrtc': {
          return (
            <>
              <CheckIcon className={classes.checkIcon} />
              <Typography>{translate('WEBRTC_CALLS_ON')}</Typography>
            </>
          );
        }
        default: {
          return (
            <>
              <AlertCircleIcon className={classes.alertCircleIcon} />
              <div className={classes.integrationDescription}>
                <Translate
                  i18nKey={'INTEGRATION_CALLS_DISABLED'}
                  components={{
                    t: <Typography color={'danger600'} type={'text4'} />,
                    l: <Typography color={'link600'} type={'text4'} underline />,
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                      <a
                        rel={'noreferrer'}
                        target={'_blank'}
                        href={
                          'https://gravitel.ru/baza-znaniy/amocrm/nastroyka-integratsii-s-amocrm/'
                        }
                      />
                    ),
                  }}
                />
              </div>
            </>
          );
        }
      }
    }
    return null;
  };

  const renderIntegrationFormContent = () => {
    if (loadingIntegration || showInitialLoading) return <PagePreloader />;
    if (errorIntegration) return <PageError />;

    return (
      <IntegrationContext.Provider value={integrationFormValue}>
        <Flex className={classes.content}>
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(handleIntegrationUpdate)}
              action={''}
              id={'integration-update'}
            >
              <div className={classes.integrationCard}>
                {isPreview ? (
                  <>
                    <Flex
                      className={classes.integrationPreviewBlock}
                      justifyContent={'spaceBetween'}
                    >
                      <Flex>
                        <PreviewAPILogo className={classes.integrationPreviewLogo} />
                        <Flex direction={'column'}>
                          <Typography type={'text2'} color={'tertiary900'} bold>
                            {translate(
                              isSimpleCallsIntegration
                                ? 'INTEGRATION_WITH_SERVICE'
                                : 'INTEGRATION_WITH',
                              { name: translate(ApiInformation.title) }
                            )}
                          </Typography>
                          <Flex
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={classes.integrationPlank}
                          >
                            <Typography
                              type={'text5'}
                              color={ApiInformation.paid ? 'link600' : 'success600'}
                              medium
                            >
                              {translate(ApiInformation.paid ? 'PAID' : 'FOR_FREE')}
                            </Typography>
                          </Flex>
                        </Flex>
                      </Flex>
                      <div>
                        <InstallModule />
                      </div>
                    </Flex>
                    <div className={classes.integrationDescription}>
                      <Typography type={'text3'} color={'tertiary900'}>
                        {translate(ApiInformation.description)}
                      </Typography>
                    </div>
                    {ApiInformation.installDescription ? (
                      <div className={classes.integrationInstallNote}>
                        <Typography type={'text4'} color={'tertiary900'}>
                          {translate(ApiInformation.installDescription)}
                        </Typography>
                      </div>
                    ) : null}
                    {ApiInformation.document && (
                      <div className={classes.integrationDocument}>
                        <a
                          rel={'noreferrer'}
                          target={'_blank'}
                          href={'/Гравител%20REST%20API.pdf'}
                          className={classes.integrationDownloadBtn}
                        >
                          <Typography type={'text3'} color={'tertiary900'}>
                            {translate('PDF_FULL_DESCRIPTION')}
                          </Typography>
                          <DownloadIcon />
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Flex justifyContent={'spaceBetween'}>
                      <div>
                        <div className={classes.integrationTitle}>
                          <Typography type={'text2'} color={'tertiary900'} bold>
                            {translate(ApiInformation.title)}
                          </Typography>
                        </div>
                        {isIntegrationEdit &&
                          currentIntegration?.status !== IntegrationStatus.Draft && (
                            <Flex className={classes.integrationSwitch} alignItems={'flexEnd'}>
                              <SwitchField
                                name={'integrationStatus'}
                                disabled={!isIntegrationEdit}
                                onClick={() => handleIntegrationSwitchAction()}
                              />
                              <Typography
                                className={classes.integrationSwitchLabel}
                                type={'text3'}
                                color={'tertiary900'}
                              >
                                {translate(
                                  setSwitchText(currentIntegration?.status, isIntegrationEdit)
                                )}
                              </Typography>
                            </Flex>
                          )}

                        <div className={classes.integrationDescription}>
                          <Typography type={'text4'} color={'tertiary900'}>
                            {translate(ApiInformation.description)}
                          </Typography>
                        </div>
                      </div>
                      {renderIdentifier()}
                    </Flex>
                    {ApiInformation.document && (
                      <div className={classes.integrationPDFButton}>
                        <a
                          rel={'noreferrer'}
                          target={'_blank'}
                          href={'/Гравител%20REST%20API.pdf'}
                          className={classes.integrationDownloadBtn}
                        >
                          <Typography type={'text3'} color={'tertiary900'}>
                            {translate('PDF_FULL_DESCRIPTION')}
                          </Typography>
                          <DownloadIcon />
                        </a>
                      </div>
                    )}
                    <Flex
                      className={classes.integrationNameField}
                      justifyContent={'spaceBetween'}
                      alignItems={'flexEnd'}
                    >
                      {renderIntegrationNameField()}
                    </Flex>
                    <Flex alignItems="center" className={classes.marginTop15}>
                      {renderIntegrationNotification()}
                    </Flex>
                  </>
                )}
              </div>

              <div className={classes.integrationAuthBlock}>
                {renderAutorizationSection()}
                {renderEmployeeList()}
                {renderSettingsContent()}
              </div>
              <Flex justifyContent={'spaceBetween'} className={classes.defaultElementWidth70}>
                {renderControls()}
              </Flex>
            </form>
          </FormProvider>
          {renderSettingCallsForResponsible()}
          <ScenarioSettings
            isOpen={scenarioSettingDialog.isOpen}
            onClose={() => handleScenarioSettingClose()}
            onComplete={handleScenarioSettingChanged}
          />
          {renderMessages()}
        </Flex>
      </IntegrationContext.Provider>
    );
  };

  return <BodyContainer>{renderIntegrationFormContent()}</BodyContainer>;
};

export default IntegrationForm;
