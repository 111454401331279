import SidebarItem from '@/components/SidebarItem/SidebarItem';
import {
  administratorPrivateRoutesProps,
  operatorPrivateRoutesProps,
  getOwnerPrivateRoutes,
  getSystemPrivateRoutes,
  userPrivateRoutesProps,
} from '@/routes';
import { useQuery } from '@apollo/client';
import { IRoute } from '@components/Routes';
import Sidebar from '@components/Sidebar';
import { AUTHENTICATION_STATE_QUERY, WIDE_SIDEBAR_STATE } from '@components/client/queries';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
// prettier-ignore
import {
  isFeatureAvailable,
  isOptionAvailable,
  useDomainFeatures,
  useDomainOptions,
} from '@/common/hooks/domainFeatures.hooks';
import { LogoHorizontalPrimaryIcon, LogoPrimary } from '@shared/assets/images/icons/logos';
import { getCurrentDomain, getRole } from '@/utils';
import { Role } from '@/client/generated/graphql';
import ErrorBoundaryCommon from '@shared/components/ErrorBoundaryCommon';
import { USER_QUERY } from '@/client/queries';
import DashboardHeader from './DashboardHeader';
import { useDashboardLayoutStyles } from './DashboardLayout.styles';

export const DashboardLayout = () => {
  const classes = useDashboardLayoutStyles();

  const { data: dataAuthStateQuery } = useQuery<{ isAuthenticated?: boolean; role?: string }>(
    AUTHENTICATION_STATE_QUERY
  );
  const role = getRole(dataAuthStateQuery?.role);

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const clientType = getCurrentDomain(userData?.user)?.client?.type;

  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWideSidebar = sidebarState?.isWide;
  const { features } = useDomainFeatures();
  const { options: domainOptions } = useDomainOptions();

  const privateRoutes = useMemo(() => {
    let routes: IRoute[];

    switch (role) {
      case Role.Manager:
      case Role.Supporter:
      case Role.Sysadmin: {
        routes = getSystemPrivateRoutes({ clientType });
        break;
      }
      case Role.Owner: {
        routes = getOwnerPrivateRoutes({ clientType });
        break;
      }
      case Role.Admin: {
        routes = administratorPrivateRoutesProps;
        break;
      }
      case Role.User: {
        routes = userPrivateRoutesProps;
        break;
      }
      case Role.Operator: {
        routes = operatorPrivateRoutesProps;
        break;
      }
      default:
        routes = [];
        break;
    }

    return routes.sort((a, b) => (a.sort || 10) - (b.sort || 10));
  }, [clientType, role]);

  const renderMenuItemsReducer = (result: IRoute[], item: IRoute) => {
    const notAvailable =
      (item.feature && !isFeatureAvailable(item.feature, features)) ||
      (item.option && !isOptionAvailable(item.option, domainOptions));

    if (!notAvailable && !item?.excluded) {
      result.push(item);
    }

    return result;
  };

  const computedWrapperClasses = clsx(classes.wrapper, {
    [classes.wrapperLeftShort]: !isWideSidebar,
    [classes.wrapperLeftLong]: isWideSidebar,
  });
  const sidebarItems = privateRoutes.reduce(renderMenuItemsReducer, []);

  return (
    <>
      <Sidebar
        LogoVertical={<LogoPrimary />}
        LogoHorizontal={<LogoHorizontalPrimaryIcon />}
        items={sidebarItems}
        ItemRender={SidebarItem}
        expandable
      />
      <main className={computedWrapperClasses}>
        <div className={classes.layoutRoot}>
          <DashboardHeader />
          <ErrorBoundaryCommon pageErrorBodyClassName={classes.pageErrorBody}>
            <Outlet />
          </ErrorBoundaryCommon>
        </div>
      </main>
    </>
  );
};

export default DashboardLayout;
