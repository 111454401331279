import { makeStyles } from '@material-ui/core';
import { FilterIcon, XIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React from 'react';

type FilterButtonsProps = {
  isFilterApplied: boolean;
  onFilterButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
};

const useFilterButtonsStyles = makeStyles(({ color: { primary, tertiary } }) => ({
  icon: {},
  xIcon: {
    color: tertiary[500],
    '&:hover': {
      color: tertiary[900],
    },
  },
  filterIcon: {
    color: tertiary[500],
  },
  filterIconWhenFilterActive: {
    color: primary[700],
  },
  filterIconWhenFilterInactive: {
    '&:hover': {
      color: tertiary[900],
    },
  },

  typography: {
    color: tertiary[500],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  typographyWhenFilterActive: {
    color: primary[700],
  },
  typographyWhenFilterInactive: {
    '&:hover': {
      color: tertiary[900],
    },
  },

  filterButton: {
    padding: 0,
    border: 0,
    '&:focus-visible $typography': {
      color: primary[600],
    },
    '&:focus-visible $icon': {
      color: primary[600],
    },
  },
  cancelButton: {
    padding: 0,
    border: 0,
    '&:focus-visible $icon': {
      color: primary[600],
    },
  },

  filterInactiveIcon: {
    color: tertiary[500],
    '&:hover': {
      color: tertiary[900],
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const FilterButtons = ({
  isFilterApplied,
  onFilterButtonClick,
  onCancelButtonClick,
  text,
}: FilterButtonsProps) => {
  const classes = useFilterButtonsStyles();

  const renderButtonContent = () => {
    if (text) {
      return (
        <Typography
          className={clsx(
            classes.typography,
            isFilterApplied
              ? classes.typographyWhenFilterActive
              : classes.typographyWhenFilterInactive
          )}
          type={'text4'}
          medium
        >
          {text}
        </Typography>
      );
    }
    return (
      <FilterIcon
        className={clsx(
          classes.icon,
          classes.filterIcon,
          isFilterApplied
            ? classes.filterIconWhenFilterActive
            : classes.filterIconWhenFilterInactive
        )}
      />
    );
  };

  const renderContent = () => {
    return (
      <>
        {isFilterApplied && (
          <Button
            className={classes.cancelButton}
            disableFocusRipple
            disableTouchRipple
            variant="text"
            onClick={onCancelButtonClick}
          >
            <XIcon className={clsx(classes.icon, classes.xIcon)} />
          </Button>
        )}

        <Button
          className={clsx(classes.filterButton)}
          disableFocusRipple
          disableTouchRipple
          variant="text"
          onClick={onFilterButtonClick}
        >
          {renderButtonContent()}
        </Button>
      </>
    );
  };

  if (!text) {
    return <>{renderContent()}</>;
  }

  return <div className={classes.wrapper}>{renderContent()}</div>;
};

export default FilterButtons;
