import { makeStyles } from '@material-ui/core';

export const useSortFilterStyles = makeStyles(
  ({ color: { tertiary, primary } }) => ({
    chevronIcon: {
      width: '0.875em',
      height: '0.875em',
      marginLeft: '0.25em',
      color: tertiary[600],
      '&:hover': {
        color: primary[600],
        cursor: 'pointer',
      },
    },
    activeChevron: {
      color: primary[700],
    },
    removeIcon: {
      color: tertiary[500],
      cursor: 'pointer',
      '&:hover': {
        color: tertiary[900],
      },
    },
    filteredIconsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    filterButton: {
      border: 0,
    },
    filterButtonText: {
      color: tertiary[500],
      '&:hover': {
        color: tertiary[900],
      },
    },
    filterButtonTextWhenFilterActive: {
      color: primary[700],
      '&:hover': {
        color: primary[700],
      },
    },
  }),
  {
    index: 2,
  }
);
