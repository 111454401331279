import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_BALANCE_INFO_QUERY, USER_QUERY } from '@/client/queries';
import { PROMISE_PAYMENT_MIN_AMOUNT, toPrecision } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { DoughnutDiagram } from '@components/Diagram';
import { useTheme } from '@material-ui/core/styles';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import Button from '@shared/components/Button';
import { DomainStatus } from '@components/typings/interfaces';
import Translate from '@shared/components/Translate';
import { BalanceDialog } from '@/components/BalanceDialog';
import Preloader from '@shared/components/Preloader';
import { format } from 'date-fns';
import clsx from 'clsx';
import PageError from '@shared/components/PageError';
import { ClientType } from '@/client/generated/graphql';
import { desktopPageSize, IDesktopWidgetProps } from '../DesktopPage.interfaces';
import { useDesktopPageStyle } from '../DesktopPage.styles';

export const BalanceWidget = ({ pageSize }: IDesktopWidgetProps) => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();
  const theme: IThemeColorOptions = useTheme();
  const {
    data: dashboardBalanceInfo,
    loading,
    refetch: updateBalanceDate,
    error: { graphQLErrors: getBalanceErrors = null } = {},
  } = useQuery(GET_DASHBOARD_BALANCE_INFO_QUERY);
  const balanceInfo = dashboardBalanceInfo?.dashboardBalanceInfo;
  const [isFillBalanceDialogOpen, setFillBalanceDialogOpen] = useState(false);
  const [isPromisePaymentDialogOpen, setPromisePaymentDialogOpen] = useState(false);
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const status = getCurrentDomain(userData?.user)?.status || DomainStatus.Unknown;
  const clientType = getCurrentDomain(userData?.user)?.client?.type;
  const isClientNotFL = clientType !== ClientType.Fl;

  const isLegalStatusFL = useMemo(() => clientType === ClientType.Fl, [clientType]);
  const isDemo = useMemo(
    () =>
      status === DomainStatus.Demo ||
      status === DomainStatus.Moderating ||
      status === DomainStatus.Confirmed,
    [status]
  );
  const isContractSigned = useMemo(
    () => !!status && status !== DomainStatus.Demo && status !== DomainStatus.Moderating,
    [status]
  );

  const balanceData = useMemo(() => {
    if (!balanceInfo) {
      return null;
    }
    const {
      currentBalance,
      averageExpense,
      credit,
      numbersCount,
      employeesCount,
      employeesLimit,
      tariffName,
      promisedPaymentAvailabilityDate,
      promisedPaymentAvailableSum,
      promisedPaymentAvailability,
      promisedPaymentBurntDate,
      promisedPaymentCreatedDate,
    } = balanceInfo;
    return {
      balance: toPrecision(currentBalance || 0, false),
      balanceRaw: currentBalance,
      averageExpense: toPrecision(averageExpense || 0, false),
      averageExpenseRaw: averageExpense,
      credit: toPrecision(credit || 0, false),
      creditRaw: credit,
      numbersCount: numbersCount || 0,
      employeeCount: employeesCount || 0,
      employeeLimit: employeesLimit || 0,
      // hack for Demo tariff
      tariffName: isDemo ? translate('TARIFF_TEST') : tariffName || '-',
      promisedPaymentAvailabilityDate: promisedPaymentAvailabilityDate
        ? new Date(promisedPaymentAvailabilityDate)
        : null,
      promisedPaymentAvailability,
      promisedPaymentBurntDate: promisedPaymentBurntDate
        ? new Date(promisedPaymentBurntDate)
        : null,
      promisedPaymentCreatedDate: promisedPaymentCreatedDate
        ? new Date(promisedPaymentCreatedDate)
        : null,
      promisedPaymentAvailableSum: promisedPaymentAvailableSum
        ? toPrecision(promisedPaymentAvailableSum, false)
        : null,
      promisedPaymentAvailableSumRaw: isDemo ? credit : promisedPaymentAvailableSum,
    };
  }, [balanceInfo, isDemo, translate]);

  const doughnutDiagramOptions = useMemo(() => {
    const setCotOut = () => {
      if (pageSize === desktopPageSize.Min) return 65;
      if (pageSize === desktopPageSize.Normal) return 75;
      return 85;
    };

    return {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      aspectRatio: 1,
      maintainAspectRatio: false,
      rotation: -150,
      circumference: 300,
      cutout: setCotOut(),
    };
  }, [pageSize]);

  const balanceDiagramData = useMemo(() => {
    if (balanceData) {
      const { tertiary, success, danger, warning } = theme?.color || {};

      const balanceRaw = balanceData.balanceRaw || 0;
      const averageExpenseRaw = balanceData.averageExpenseRaw || 0;

      const balanceAmount = () => {
        if (balanceRaw === 0 && averageExpenseRaw === 0) {
          return {
            fillValue: [0, 100],
            fillColor: [warning[600], tertiary[200]],
          };
        }
        if (balanceRaw <= 0) {
          return {
            fillValue: [100, 0],
            fillColor: [danger[500], tertiary[200]],
          };
        }
        if (balanceRaw > averageExpenseRaw) {
          return {
            fillValue: [100, 0],
            fillColor: [success[600], tertiary[200]],
          };
        }
        const getBalancePercent = (balanceRaw / averageExpenseRaw) * 100;
        const setFillColor = () => {
          if (balanceRaw < (averageExpenseRaw / 30) * 5) {
            return warning[600];
          }
          return success[600];
        };
        return {
          fillValue: [getBalancePercent, 100 - getBalancePercent],
          fillColor: [setFillColor(), tertiary[200]],
        };
      };
      const { fillValue, fillColor } = balanceAmount();
      return {
        datasets: [
          {
            data: fillValue,
            backgroundColor: fillColor,
            borderWidth: 0,
            borderRadius: 10,
          },
        ],
      };
    }
    return {
      datasets: [],
    };
  }, [theme, balanceData]);

  const averageExpenseDiagramData = useMemo(() => {
    if (balanceData) {
      const { tertiary, links } = theme?.color || {};
      const { averageExpenseRaw } = balanceData;
      const averageExpenseFillValue = averageExpenseRaw ? 100 : 0;
      const averageExpenseEmptyValue = averageExpenseRaw ? 0 : 100;
      return {
        datasets: [
          {
            data: [averageExpenseFillValue, averageExpenseEmptyValue],
            backgroundColor: [links[500], tertiary[200]],
            borderWidth: 0,
            borderRadius: 10,
          },
        ],
      };
    }
    return {
      datasets: [],
    };
  }, [theme, balanceData]);

  const creditDiagramData = useMemo(() => {
    if (!balanceData) {
      return {
        datasets: [],
      };
    }

    const { tertiary, links, success, warning } = theme?.color || {};

    const { promisedPaymentAvailability, promisedPaymentBurntDate } = balanceData;

    const balanceRaw = balanceData.balanceRaw || 0;
    const creditRaw = balanceData.creditRaw || 0;
    const promisedPaymentAvailableSumRaw = balanceData.promisedPaymentAvailableSumRaw || 0;

    const creditAmount = () => {
      if (creditRaw) {
        if (balanceRaw >= creditRaw) {
          return {
            fillValue: [100, 0],
            fillColor: [warning[600], tertiary[200]],
          };
        }
        if (creditRaw - Math.abs(balanceRaw) <= 0) {
          return {
            fillValue: [0, 100],
            fillColor: [warning[600], tertiary[200]],
          };
        }
        const getCreditUsagePercent = (Math.abs(balanceRaw) / creditRaw) * 100;
        return {
          fillValue: [100 - getCreditUsagePercent, getCreditUsagePercent],
          fillColor: [warning[600], tertiary[200]],
        };
      }
      if (promisedPaymentAvailability) {
        return {
          fillValue: [0, 100],
          fillColor: [links[600], success[600]],
        };
      }
      if (!promisedPaymentAvailableSumRaw && !isDemo) {
        return {
          fillValue: [0, 100],
          fillColor: [links[600], tertiary[200]],
        };
      }
      if (
        (!promisedPaymentAvailability && !promisedPaymentBurntDate) ||
        promisedPaymentAvailableSumRaw - balanceRaw < 0
      ) {
        return {
          fillValue: [0, 100],
          fillColor: [links[600], tertiary[200]],
        };
      }
      const getCreditUsagePercent =
        balanceRaw < 0 ? (Math.abs(balanceRaw) / promisedPaymentAvailableSumRaw) * 100 : 100;
      return {
        fillValue: [getCreditUsagePercent, 100 - getCreditUsagePercent],
        fillColor: [warning[600], tertiary[200]],
      };
    };
    const { fillValue, fillColor } = creditAmount();
    const borderRadius =
      fillValue && fillColor
        ? [10, { innerStart: 0, outerStart: 0, innerEnd: 10, outerEnd: 10 }]
        : 10;
    return {
      datasets: [
        {
          data: fillValue,
          backgroundColor: fillColor,
          borderWidth: 0,
          borderRadius,
        },
      ],
    };
  }, [balanceData, theme?.color, isDemo]);

  function handleCloseBalanceDialog() {
    setFillBalanceDialogOpen(false);
  }

  function handleClosePromiseDialog() {
    setPromisePaymentDialogOpen(false);
  }

  function handlePromisePaymentSuccess() {
    updateBalanceDate().then();
  }

  const renderCreditAvailableInfo = () => {
    const {
      promisedPaymentAvailability,
      promisedPaymentCreatedDate,
      promisedPaymentAvailableSum,
      creditRaw,
    } = balanceData || {};
    const promisedPaymentBurntDate = balanceData?.promisedPaymentBurntDate || new Date();

    if ((!promisedPaymentAvailability && !promisedPaymentAvailableSum && !creditRaw) || isDemo) {
      return null;
    }

    return (
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        className={classes.desktopDoughnutDiagramBottomInfo}
      >
        {promisedPaymentAvailability && !promisedPaymentCreatedDate ? (
          <Button clear onClick={() => setPromisePaymentDialogOpen(true)}>
            <Typography type={'text4'} underline color={'link600'}>
              {translate('CONNECT')}
            </Typography>
          </Button>
        ) : (
          promisedPaymentBurntDate && (
            <Typography type={'text4'} color={'tertiary900'}>
              {translate('UP_TO')} {format(promisedPaymentBurntDate, 'dd.MM.yyyy')}
            </Typography>
          )
        )}
      </Flex>
    );
  };

  const renderCreditData = () => {
    const {
      balanceRaw = 0,
      promisedPaymentAvailabilityDate,
      promisedPaymentAvailableSum,
      promisedPaymentAvailability,
      creditRaw,
    } = balanceData || {};
    if (isDemo) {
      return (
        <>
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('CREDIT_IN_DEMO')}
          </Typography>
          <Typography type={'text2'} color={'tertiary900'}>
            {`${balanceData?.credit || 0} ₽`}
          </Typography>
        </>
      );
    }
    if (creditRaw) {
      return (
        <>
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('CREDIT_AMOUNT_SHORT')}
          </Typography>
          <Typography type={'text2'} color={'tertiary900'}>
            {`${balanceData?.credit || 0} ₽`}
          </Typography>
        </>
      );
    }
    if (promisedPaymentAvailabilityDate) {
      return (
        <Typography type={'text4'} color={'tertiary700'}>
          {translate('CREDIT_AVAILABLE_AT', {
            date: format(promisedPaymentAvailabilityDate, 'dd.MM.yyyy'),
          })}
        </Typography>
      );
    }
    if (promisedPaymentAvailableSum && promisedPaymentAvailability) {
      if (typeof balanceRaw === 'number' && balanceRaw > 300) {
        return (
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('CREDIT_AVAILABLE_AT_SUM', { amount: PROMISE_PAYMENT_MIN_AMOUNT })}
          </Typography>
        );
      }
      return (
        <Translate
          i18nKey={'PROMISE_PAYMENT_AVAILABLE'}
          components={{
            t: <Typography type={'text4'} color={'tertiary700'} />,
            n: <br />,
          }}
        />
      );
    }
    return (
      <Typography type={'text4'} color={'tertiary700'}>
        {translate('CREDIT_NOT_AVAILABLE')}
      </Typography>
    );
  };

  const renderClientInformation = () => {
    const computerClasses = clsx({
      [classes.desktopBalanceInfoItem]: !isLegalStatusFL || isDemo,
      [classes.desktopBalanceInfoItemFL]: isLegalStatusFL && !isDemo,
    });
    return (
      <>
        <Flex justifyContent={'flexEnd'} direction={'column'} className={computerClasses}>
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('COUNT_NUMBERS')}
          </Typography>
          <Typography type={'text4'} color={'tertiary900'} bold>
            {balanceData?.numbersCount || 0}
          </Typography>
        </Flex>
        <Flex justifyContent={'flexEnd'} direction={'column'} className={computerClasses}>
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('EMPLOYEES_COUNT')}
          </Typography>
          <Typography type={'text4'} color={'tertiary900'} bold>
            {balanceData?.employeeCount || 0}
            {balanceData?.employeeLimit !== -1 && (
              <Typography type={'default'} color={'tertiary700'}>
                {` ${translate('OUT_OF').toLowerCase()} ${balanceData?.employeeLimit || 0}`}
              </Typography>
            )}
          </Typography>
        </Flex>
        <Flex justifyContent={'flexEnd'} direction={'column'} className={computerClasses}>
          <Typography type={'text4'} color={'tertiary700'}>
            {translate('YOUR_TARIFF')}
          </Typography>
          <Typography type={'text4'} color={'tertiary900'} bold>
            {balanceData?.tariffName || '-'}
          </Typography>
        </Flex>
      </>
    );
  };

  if (loading) {
    return (
      <Flex justifyContent={'center'} alignItems={'center'} className={classes.desktopSection}>
        <Preloader size={'large'} />
      </Flex>
    );
  }

  if (getBalanceErrors) {
    return (
      <div className={classes.desktopSection}>
        <PageError />
      </div>
    );
  }

  return (
    <div className={classes.desktopSection}>
      <Flex
        className={classes.desktopSectionHeader}
        justifyContent={'spaceBetween'}
        alignItems={'flexStart'}
      >
        <Typography type={'text2'} color={'tertiary900'} bold>
          {translate('FINANCE')}
        </Typography>
        <Flex>{(!isLegalStatusFL || isDemo) && renderClientInformation()}</Flex>
      </Flex>
      <Flex justifyContent={'spaceBetween'}>
        <div className={classes.desktopDoughnutDiagram}>
          <DoughnutDiagram data={balanceDiagramData} options={doughnutDiagramOptions} />
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            className={classes.desktopDoughnutDiagramInfo}
          >
            <Typography type={'text4'} color={'tertiary700'}>
              {translate('BALANCE')}
            </Typography>
            <Typography type={'text2'} color={'tertiary900'}>
              {`${balanceData?.balance || 0} ₽`}
            </Typography>
          </Flex>
          {isContractSigned && (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              className={classes.desktopDoughnutDiagramBottomInfo}
            >
              <Button clear onClick={() => setFillBalanceDialogOpen(true)}>
                <Typography type={'text4'} underline color={'link600'}>
                  {translate(isClientNotFL ? 'GENERATE_INVOICE' : 'FILL_UP_BALANCE')}
                </Typography>
              </Button>
            </Flex>
          )}
        </div>
        <div className={classes.desktopDoughnutDiagram}>
          <DoughnutDiagram data={averageExpenseDiagramData} options={doughnutDiagramOptions} />
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            className={classes.desktopDoughnutDiagramInfo}
          >
            <Typography type={'text4'} color={'tertiary700'}>
              {translate('AVERAGE_EXPENSE')}
            </Typography>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('RUB_PER_MONTH_SHORT', { amount: balanceData?.averageExpense || 0 })}
            </Typography>
          </Flex>
        </div>
        <div className={classes.desktopDoughnutDiagram}>
          {!isDemo && isLegalStatusFL ? (
            <Flex direction={'columnReverse'}>{renderClientInformation()}</Flex>
          ) : (
            <>
              <DoughnutDiagram data={creditDiagramData} options={doughnutDiagramOptions} />
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                direction={'column'}
                className={classes.desktopDoughnutDiagramInfo}
              >
                {renderCreditData()}
              </Flex>
              {renderCreditAvailableInfo()}
            </>
          )}
        </div>
      </Flex>
      <BalanceDialog
        isFillBalanceDialogOpen={isFillBalanceDialogOpen}
        isPromisePaymentDialogOpen={isPromisePaymentDialogOpen}
        onCloseBalanceDialog={handleCloseBalanceDialog}
        onClosePromiseDialog={handleClosePromiseDialog}
        onPromisePaymentSuccess={handlePromisePaymentSuccess}
        onSuccessCallbackUrl={'dashboard/'}
      />
    </div>
  );
};

export default memo(BalanceWidget);
