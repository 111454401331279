import { DocumentModel } from '@/client/generated/graphql';
import { getStorageItem } from '@components/storage/storage';
import { SearchIcon } from '@shared/assets/images/icons';

import Button from '@shared/components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICellProps } from '@components/LazyTable';
import ButtonDownload from '@shared/components/ButtonDownload';
import getBasicButtonDownloadHandleErrorCallback from '@components/utils/getBasicButtonDownloadHandleErrorCallback';
import { DocumentsType } from '@components/typings/interfaces/documents';
import { getCurrentClient } from '@/utils';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { useDocumentsStyle } from '../DocumentsList.styles';

type DownloadURL = string;
type Filename = string;

export const DocumentsActionCell = ({ item }: ICellProps<DocumentModel>) => {
  const classes = useDocumentsStyle();
  const [translate] = useTranslation();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });

  function handleDocumentViewClick(hash: string) {
    let url = process.env.API_URL;
    const token = getStorageItem('token');
    if (token && url) {
      url += `/files/view?filename=${hash}&token=${token}`;
      window.open(url);
    }
  }

  function getDocumentDownloadData(doc: DocumentModel): [DownloadURL, Filename] {
    const url = process.env.API_URL;
    const token = getStorageItem('token');
    const { account = '' } = getCurrentClient(userData?.user) || {};

    if (!token || !url) {
      return ['', ''];
    }

    let documentName;
    const fileExtension = doc.document.split('.').pop();

    switch (doc.type) {
      case DocumentsType.Passport:
        documentName = `${translate(doc.type)}. ${doc.comment}.${fileExtension}`;
        break;
      case DocumentsType.Contract:
        documentName = `${translate(doc.type)} ${account}-Л.${fileExtension}`;
        break;
      case DocumentsType.CommunicationsContract:
        documentName = `${translate(doc.type)} ${account}-С.${fileExtension}`;
        break;
      case DocumentsType.Refusal:
      case DocumentsType.Order:
        documentName = `${translate(doc.type)} ${doc.number}.${fileExtension}`;
        break;
      default:
        documentName = `${translate(doc.type)}.${fileExtension}`;
        break;
    }

    return [
      `${url}/files/get?filename=${doc.document}&displayName=${documentName}&token=${token}`,
      documentName,
    ];
  }

  if (!item) {
    return <div />;
  }

  const [downloadURL, filename] = getDocumentDownloadData(item);

  return (
    <>
      <Button
        variant={'secondary'}
        className={classes.documentsButton}
        disabled={!item?.document || !!item?.document?.match(/^\d+-\d+$/)}
        onClick={() => handleDocumentViewClick(item?.document)}
      >
        <SearchIcon />
      </Button>
      <ButtonDownload
        className={classes.marginLeft1}
        url={downloadURL}
        filename={filename}
        disabled={!item?.document || !!item?.document?.match(/^\d+-\d+$/)}
        onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
      />
    </>
  );
};
