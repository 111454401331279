import resultify from '@shared/utils/resultify';
import heic2any from 'heic2any';
import { createNewFileSafe } from '@shared/utils/files';

export const heicToJpegSafe = async (
  file: File,
  lastModifiedDate?: Date
): Promise<
  { type: 'success'; file: File } | { type: 'error'; error: any; additionalMessage?: string }
> => {
  const fileNameParts = file.name.split('.');
  const fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1];

  if (fileExtension === '') {
    return {
      type: 'error',
      error: Error(
        'heic/heif file extension is actually an empty string, this behavior is unexpected.'
      ),
    } as const;
  }

  const heicToJpegBlobResult = await resultify(heic2any({ blob: file, toType: 'image/jpeg' }));
  if (heicToJpegBlobResult.type === 'error') {
    return {
      type: 'error',
      error: heicToJpegBlobResult.error,
      additionalMessage: `Failed to convert ${fileExtension} file to jpeg blob.`,
    } as const;
  }

  let jpegBlob: Blob;
  if (Array.isArray(heicToJpegBlobResult.data)) {
    if (heicToJpegBlobResult.data.length === 0 || !heicToJpegBlobResult.data[0]) {
      return {
        type: 'error',
        error: Error(
          `Unexpected empty blob array retrieved after converting ${fileExtension} to jpeg.`
        ),
      } as const;
    }
    jpegBlob = heicToJpegBlobResult.data[0];
  } else {
    jpegBlob = heicToJpegBlobResult.data;
  }

  const newJpegFileResult = createNewFileSafe([jpegBlob], `${fileNameWithoutExtension}.jpeg`, {
    lastModified: lastModifiedDate ? lastModifiedDate.getDate() : undefined,
  });
  if (newJpegFileResult.type === 'error') {
    return {
      type: 'error',
      error: newJpegFileResult.error,
      additionalMessage: "Failed to create file from 'jpeg from heic' blob.",
    } as const;
  }

  return { type: 'success', file: newJpegFileResult.data } as const;
};
